<template>
  <div class="home">
    <Header />
    <main>
        <img src="img/notfound.jpg" class="center-image" />
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'NotFoundView', 
  components: {
    Header, Footer
  }
}
</script>

<style scoped>
main {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.center-image {
    max-width: 100%;
    max-height: 100%;
}
</style>