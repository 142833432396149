const store = {

    saveToken(value) {
        localStorage.setItem('jwtTokenInfoEstetica', value);
    },

    removeToken() {
        localStorage.removeItem('jwtTokenInfoEstetica');
    },

    getToken() {
        return localStorage.getItem('jwtTokenInfoEstetica');
    },

    hasToken: () => {
        return !!localStorage.getItem('jwtTokenInfoEstetica');
    }

};

export default store;