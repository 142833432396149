<template>
  <div v-show="showContent" class="admin-view">
    <h1>Amministrazione</h1>
    <AdminMenu @menu-click="handleMenuClick" :user="user || {}" />
    <hr />
    <img src="img/backend-vector-3754398.jpg" class="icon-admin" v-show="isIconVisibile"/>
    <component :is="selectedComponent"></component>
  </div>
</template>

<script>
import { shallowRef, ref, computed } from "vue";
import apiConfig from "@/config/api";
import { request, responseKO } from "@/config/api";
import store from "@/store";
import AdminMenu from "../components/AdminComponents/AdminMenu.vue";
import AdminUsers from "../components/AdminComponents/AdminUsers.vue";
import AdminMagazines from "../components/AdminComponents/AdminMagazines.vue";
import AdminFileManager from "../components/AdminComponents/AdminFileManager.vue";

const adminUsers = shallowRef(AdminUsers);
const adminMagazines = shallowRef(AdminMagazines);
const adminFiles = shallowRef(AdminFileManager);

export default {
  components: {
    AdminMenu,
    AdminUsers,
    AdminMagazines,
    AdminFileManager
  },
  data() {
    return {
      user: null,
      selectedComponent: null,
      showContent: false,
      isIconVisibile: true,
    };
  },
  methods: {
    async isAdmin() {
      const response = await request(
        apiConfig.noAuthEndpoints.me,
        apiConfig.methods.post,
        null,
        store.getToken()
      );
      if (response.ok) {
        const data = await response.json();
        if(data) {
          this.user = data;
          if(this.user.role == "admin" || this.user.role == "editor") {
            this.showContent = true;
            this.$nextTick(() => {
              this.$emit('user-updated', this.user);
            });
          } else {
            responseKO();  
          }
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000);
          responseKO();
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
        responseKO();
      }
    },
    handleMenuClick(value) {
      const componentMapping = {
        user: adminUsers,
        magazine: adminMagazines,
        files: adminFiles
      };
      this.isIconVisibile = false,
      this.selectedComponent = componentMapping[value];
    }
  },
  beforeMount() {
    this.isAdmin();
  },
};
</script>

<style scoped>
h1 {
  margin-top: 15px;
  text-align: center;
}
.admin-view {
  display: grid;
  place-items: center;
}
.icon-admin {
  width: 20%;
  margin: 0 auto;
}
</style>