<template>
  <div class="cta_subscribe">
    <div class="container-fluid h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-6 p-0">
          <div class="block_1">
            <figure><img src="img/doctors_icon.svg" height="75" alt="" /></figure>
            <h3>Sei un medico?</h3>
            <p>
              The service allows you to get maximum visibility online and to
              manage appointments and contacts coming from the site, in a simple
              and fast way.
            </p>
            <a href="" class="btn_1">Iscriviti</a>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <div class="block_2">
            <figure><img src="img/hospital-patient-icon.svg" height="75" /></figure>
            <h3>Sei un paziente?</h3>
            <p>
              Choosing a specialist has never been so fast! You can filter
              search results by location and medical specialization, and book
              medical examination online.
            </p>
            <a href="" class="btn_1">Iscriviti</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

</style>