<template>
    <div>
        <Header />
        <main>
            <div class="container" v-if="user">
                <div class="profileTabsheet">
                    <h3 class="title" v-if="user">Profilo di {{ user.name }}</h3>
                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-user-tab" data-bs-toggle="tab" data-bs-target="#nav-user" type="button" role="tab" aria-controls="nav-user" aria-selected="true" @click="setActiveTab('user')">Utenza</button>
                            <button class="nav-link" id="nav-anagr-tab" data-bs-toggle="tab" data-bs-target="#nav-anagr" type="button" role="tab" aria-controls="nav-anagr" aria-selected="false" v-if="user && user.role === 'doctor'" @click="setActiveTab('anagr')">Anagrafica</button>
                            <button class="nav-link" id="nav-doctor-tag-tab" data-bs-toggle="tab" data-bs-target="#nav-doctor-tag" type="button" role="tab" aria-controls="nav-doctor-tag" aria-selected="false" v-if="user && user.role === 'doctor'" @click="setActiveTab('doctor-tag')">Indirizzo medico</button>
                            <button class="nav-link" id="nav-app-tab" data-bs-toggle="tab" data-bs-target="#nav-app"
                                type="button" role="tab" aria-controls="nav-app" aria-selected="false" v-if="user && user.role === 'user'" @click="setActiveTab('app')">Appuntamenti</button>
                            <button class="nav-link" id="nav-agenda-tab" data-bs-toggle="tab" data-bs-target="#nav-agenda"
                                type="button" role="tab" aria-controls="nav-agenda" aria-selected="false" v-if="user && user.role === 'doctor'" @click="setActiveTab('agenda')">Agenda</button>
                            <button class="nav-link" id="nav-services-tab" data-bs-toggle="tab" data-bs-target="#nav-services"
                                type="button" role="tab" aria-controls="nav-services" aria-selected="false" v-if="user && user.role === 'doctor'" @click="setActiveTab('services')">Trattamenti</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-user" role="tabpanel" aria-labelledby="nav-user-tab" v-show="activeTab === 'user'">
                            <ProfileTabUser  :user="user" v-if="activeTab === 'user' && user" />
                        </div>
                        <div class="tab-pane fade" id="nav-anagr" role="tabpanel" aria-labelledby="nav-anagr-tab" v-show="activeTab === 'anagr'">
                            <ProfileTabAnagrafica :user="user" :doctor="doctor" v-if="activeTab === 'anagr' && user && doctor && user.role === 'doctor'" />
                        </div>
                        <div class="tab-pane fade" id="nav-doctor-tag" role="tabpanel" aria-labelledby="nav-doctor-tag-tab" v-show="activeTab === 'doctor-tag'">
                            <ProfileDoctorTag :user="user" :doctor="doctor" v-if="activeTab === 'doctor-tag' && user && doctor && user.role === 'doctor'" />
                        </div>
                        <div class="tab-pane fade" id="nav-app" role="tabpanel" aria-labelledby="nav-app-tab" v-show="activeTab === 'app'">
                            <ProfileTabApp :user="user" v-if="activeTab === 'app' && user && user.role === 'user'" />
                        </div>
                        <div class="tab-pane fade" id="nav-agenda" role="tabpanel" aria-labelledby="nav-agenda-tab" v-show="activeTab === 'agenda'">
                            <ProfileTabAgenda :user="user" :doctor="doctor" v-if="activeTab === 'agenda' && user && doctor && user.role === 'doctor'" />
                        </div>
                        <div class="tab-pane fade" id="nav-services" role="tabpanel" aria-labelledby="nav-services-tab" v-show="activeTab === 'services'">
                            4
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- /main -->
        <Footer />
    </div>
</template>
    
<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request, requestWihtoutPreload, responseKO } from '@/config/api'
import store from "@/store"
import ProfileTabUser from '@/components/ProfileComponents/ProfileTabUser.vue'
import ProfileTabAgenda from '@/components/ProfileComponents/ProfileTabAgenda.vue'
import ProfileTabAnagrafica from '@/components/ProfileComponents/ProfileTabAnagrafica.vue'
import ProfileTabApp from '@/components/ProfileComponents/ProfileTabApp.vue'
import ProfileDoctorTag from '@/components/ProfileComponents/ProfileDoctorTag.vue'

export default {
    name: 'ProfileView',
    components: {
        Header, Footer, ProfileTabUser, ProfileTabAnagrafica, ProfileDoctorTag, ProfileTabAgenda, ProfileTabApp
    },
    data() {
        return {
            user: null,
            doctor: null,
            activeTab: 'user'
        }
    },
    mounted() {
        this.me();
    },
    methods: {
        setActiveTab(tabName) {
            this.activeTab = tabName;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if(this.user.role === 'doctor') {
                    await this.getDoctor();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
                responseKO();
            }
        },
        async getDoctor() {
            const endpoint = apiConfig.noAuthEndpoints.doctorByUserId + "/" + this.user.id;
            const response = await requestWihtoutPreload(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                this.doctor = data.result.doctor;
                if (data.message === "OK") {
                    this.title = this.doctor.title;
                    this.address = this.doctor.address;
                    this.number = this.doctor.number;
                    this.cap = this.doctor.cap;
                    this.city = this.doctor.city;
                    this.code = this.doctor.code;
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000); 
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000); 
            }
        },
    }
}
</script>
    
<style scoped>

.title {
    margin-top: 25px;
    text-align: center;
}
.profileTabsheet {
    padding: 15px 0 30px 0;
}
</style>