<template>
  <div class="admin-users-section">
    <div v-if="showResult" class="result"><p v-bind:class="result" v-html="resultContent"></p></div>
    <table>
      <thead>
        <th>Nome</th>
        <th>Email</th>
        <th>Ruolo</th>
        <th class="enabled-column">Abilitato</th>
        <th class="action-column" @click="openModal('add')"><button>+ aggiungi</button></th>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ showRole(user.role) }}</td>
          <td v-bind:class="{'enabled-cell': user.enabled, 'disabled-cell': !user.enabled}">{{ user.enabled ? 'Sì' : 'No' }}</td>
          <td class="action-column" @click="openModal('edit', user)"><button>modifica</button></td>
        </tr>
      </tbody>
    </table>    
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>{{ modalTitle }}</h2>
        <div v-if="showResult" class="result">
          <br><p v-bind:class="result" v-html="resultContent"></p>
        </div>
        <form @submit.prevent="handleSubmit">
          <label >Email:</label>
          <input type="hidden" v-model="formData.id" />
          <input type="email" required v-model="formData.email" :disabled="isSuperAdminModal" />
          <div v-if="!isEdit">
            <label >Password:</label>
            <input type="password" required v-model="formData.password" />
          </div>
          <label >Nome completo:</label>
          <input type="text" required v-model="formData.name" :disabled="isSuperAdminModal" />
          <div v-if="formData.role !== 'admin'">
            <label >Ruolo:</label>
            <select v-model="formData.role" required :disabled="isSuperAdminModal">
                <option value="editor">Editore</option>
                <option value="doctor">Dottore</option>
                <option value="user">Utente</option>
            </select>
          </div>
          <div v-if="formData.role === 'doctor'">
            <label >Link all'immagine grande del profilo del dottore:</label>
            <input type="text" v-model="formData.profileImageLink" :disabled="isSuperAdminModal" />
            <label >Link all'immagine ridotta del dottore:</label>
            <input type="text" v-model="formData.thumbnailImageLink" :disabled="isSuperAdminModal" />
          </div>
          <div v-if="formData.role === 'editor'">
            <label >Link all'immagine per la firma dell'articolo:</label>
            <input type="text" v-model="formData.smallImage" :disabled="isSuperAdminModal" />
          </div>

          <div class="buttons">
              <button class="enable-user-button" @click="enableUser()" v-show="!isSuperAdminModal && isEdit && !this.selectedUser.enabled">Abilita</button>  
              <button class="disable-user-button" @click="disableUser()" v-show="!isSuperAdminModal && isEdit && this.selectedUser.enabled">Disabilita</button>
              <button @click="changePassword()" v-show="isEdit">Cambia password</button>
              <button @click="closeModal()">Annulla</button>
              <button @click="handleModalAction()" v-show="!isSuperAdminModal">Salva</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="isModalChangePasswordOpen" class="change-password-modal">
      <div class="change-password-modal-content">
        <h2>Cambia password</h2>
        <form @submit.prevent="handleSubmit">
          <input type="hidden" v-model="formData.id" />
          <label>Password:</label>
          <input type="password" required v-model="formChangePasswordData.password" />
          <label>Conferma password:</label>
          <input type="password" required v-model="formChangePasswordData.confirmPassword" />
          <div class="buttons">
            <button @click="closeChangePasswordModal()">Annulla</button>
            <button @click="updatePassword()">Salva</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiConfig, { request, responseKO } from '@/config/api';
import store from "@/store";
import dayjs from 'dayjs';

export default {
  data() {
    return {
      users: [],
      doctor: null,
      modalType: '',
      modalTitle: '',
      isModalOpen: false,
      isModalChangePasswordOpen: false,
      isSuperAdminModal: false,
      formData: {
        id: '',
        name: '',
        email: '',
        password: '',
        role: '',
        profileImageLink: '',
        thumbnailImageLink: '',
        smallImage: ''
      },
      formChangePasswordData: {
        password: '',
        confirmPassword: ''
      },
      isEdit: false,
      selectedUser: null,
      result: '',
      showResult: false
    };
  },
  methods: {
    resetFormData() {
        this.formData.email = '';
        this.formData.password = '';
        this.formData.name = '';
        this.formData.role = '';
        this.formData.profileImageLink = '';
        this.formData.thumbnailImageLink = '';
        this.formData.smallImage = '',
        this.doctor = null
    },
    handleRoleChange() {
      return this.formData.role === 'doctor'
    },
    async openModal(type, user = null) {
      this.isModalOpen = true;
      this.modalType = type;
      this.selectedUser = user;
      if (this.modalType === 'add') {
        this.modalTitle = "Aggiungi utente";
        this.isEdit = false;
        this.formData = { name: '', email: '', password: '', role: '',  profileImageLink: '', thumbnailImageLink: '', smallImage: ''};
      } else if (this.modalType === 'edit') {
        this.modalTitle = "Modifica "+this.selectedUser.name;
        this.isEdit = true;
        if(this.selectedUser.role === 'doctor') {
          await this.getDoctor();
          this.formData = { ...user, profileImageLink: this.doctor.profile_image_link, thumbnailImageLink: this.doctor.thumbnail_image_link };
        } else if(this.selectedUser.role === 'editor') {
          this.formData = { ...user, smallImage: this.selectedUser.small_pic }; 
        }else {
          this.formData = { ...user };
        }
        this.isSuperAdminModal = this.isSuperAdmin(user);
      }
    },
    async getDoctor() {
      const endpoint = apiConfig.noAuthEndpoints.doctorByUserId + "/" + this.selectedUser.id;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.doctor = data.result.doctor;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    showRole(role) {
      if(role == "editor") return "Editore";
      if(role == "doctor") return "Dottore";
      if(role == "user") return "Utente";
    },
    closeModal() {
      this.isModalOpen = false;
      this.modalType = '';
      this.selectedUser = null;
      this.resetFormData();
    },
    closeChangePasswordModal() {
      this.isModalChangePasswordOpen = false;
      this.formChangePasswordData.password = ''
      this.formChangePasswordData.confirmPassword = ''
    },
    handleModalAction() {
      if (this.modalType === 'add') {
        this.addUser();
      } else if (this.modalType === 'edit') {
        this.editUser();
      }
      this.resetFormData();
    },
    async fetchUsers() {
      const response = await request(apiConfig.endpoints.userList, apiConfig.methods.get, null, store.getToken());
      if (response.ok) {
        const data = await response.json();
        this.users = data.users;
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
        responseKO();
      }     
    },
    async updatePassword() {
      const id = this.formData.id;
      const password1 = this.formChangePasswordData.password;
      const password2 = this.formChangePasswordData.confirmPassword;
      // Verifica che tutti i campi siano compilati
      if (!password1 || !password2) {
        showNotification("Compila tutti i campi prima di continuare.", "warning", 3000);
        return;
      }
      if (password1 !== password2) {
        showNotification("Le password indicate sono diverse, riprova.", "warning", 3000);
        return;
      }
      const requestBody = {
        password1,
        password2
      };
      const changePasswordEndpoint = apiConfig.endpoints.changePasswordUser+'/'+id;
      const response = await request(changePasswordEndpoint, apiConfig.methods.post, requestBody, store.getToken());
      if (response.ok) {
        showNotification("La password è stata cambiata correttamente.", "success", 3000);
      } else {
        
        responseKO();
      } 
      this.closeChangePasswordModal();
    },
    formatDateTime(dateTimeString) {
      return dayjs(dateTimeString).format('DD/MM/YYYY');
    },
    isSuperAdmin(user) {
      return user.id === 1 && user.role === "admin";
    },
    setResult(type, message) {
      this.result = type;
      this.resultContent = message;
      this.showResult = true
      setTimeout(() => {
        this.result = '',
        this.resultContent = '',
        this.showResult = false
      }, 6500);
    },
    async addUser() {
      const { name, email, password, role, profileImageLink, thumbnailImageLink, smallImage } = this.formData;
      // Verifica che tutti i campi siano compilati
      if (!name || !email || !password || !role) {
        showNotification("Compila tutti i campi prima di aggiungere un utente.", "warning", 3000);
        return;
      }
      const requestBody = {
        name,
        email,
        password,
        role,
        profileImageLink,
        thumbnailImageLink,
        smallImage
      };
      try {
        const response = await request(apiConfig.endpoints.userCreate, apiConfig.methods.post, requestBody, store.getToken());
        if (response.ok) {
          showNotification("Utente aggiunto con successo.", "success", 3000);
          this.fetchUsers();
        } else {
          showNotification("Errore nell'aggiunta dell'utente.", "error", 3000);
        }
      } catch (error) {
        showNotification("Errore nell'aggiunta dell'utente.", "error", 3000);
      }
      
      this.closeModal();
    },
    async editUser() {
      const { name, email, role, profileImageLink, thumbnailImageLink, smallImage } = this.formData;
      // Verifica che tutti i campi siano compilati
      if (!name || !email || !role) {
        showNotification("Compila tutti i campi prima di modificare l'utente.", "warning", 3000);
        return;
      }
      const requestBody = {
        name,
        email,
        role,
        profileImageLink,
        thumbnailImageLink,
        smallImage
      };
      try {
        const updateEndpoint = apiConfig.endpoints.userUpdate+'/'+this.formData.id;
        const response = await request(updateEndpoint, apiConfig.methods.put, requestBody, store.getToken());
        if (response.ok) {
          showNotification("Utente modificato con successo.", "success", 3000);
          this.fetchUsers();
        } else {
          showNotification("Errore nella modifica dell'utente.", "error", 3000);
        }
      } catch (error) {
        showNotification("Errore nella modifica dell'utente.", "error", 3000);
      }
      this.closeModal();
    },
    async enableUser() {
      try {
        const deleteEndpoint = apiConfig.endpoints.userEnable+'/'+this.formData.id;
        const response = await request(deleteEndpoint, apiConfig.methods.post, null, store.getToken());
        if (response.ok) {
          showNotification("Utente abilitato.", "success", 3000);
          this.fetchUsers();
        } else {
          showNotification("Errore nell'attivazione' dell'utente.", "error", 3000);
        }
      } catch (error) {
        showNotification("Errore nell'attivazione dell'utente.", "error", 3000);
      }
      this.closeModal();
    },
    async disableUser() {
      try {
        const deleteEndpoint = apiConfig.endpoints.userDisable+'/'+this.formData.id;
        const response = await request(deleteEndpoint, apiConfig.methods.post, null, store.getToken());
        if (response.ok) {
          showNotification("Utente disabilitato.", "success", 3000);
          this.fetchUsers();
        } else {
          showNotification("Errore nella disabilitazione dell'utente.", "error", 3000);
        }
      } catch (error) {
        showNotification("Errore nella disabilitazione dell'utente.", "error", 3000);
      }
      this.closeModal();
    },
    changePassword() {
      this.isModalChangePasswordOpen = true;
    }
  },
  mounted() {
    this.fetchUsers();
  }
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  text-align: left;
  padding: 8px;
  width: auto;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.admin-users-section {
  width: 100%;
  padding: 0 30px 30px 30px;
}

.action-column {
  text-align: right;
  width: 100px;
}

.action-column button {
  width: 100%;
  margin-top: 5px;
}

.enabled-column {
  width: 100px;
}

form {
  display: contents;
  max-width: 420px;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  /* font-size: 0.6em; */
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input, select {
  outline: none;
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}

.modal {
  display: block; 
  position: fixed;
  z-index: 100; 
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

.change-password-modal {
  display: block; 
  position: fixed;
  z-index: 110; 
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.change-password-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
}

.submit {
  text-align: center;
}

.buttons {
  padding: 5px;
  margin-top: 20px;
  width: auto;
  text-align: center;
}

.buttons button {
  border-radius: 5px;
  margin-right: 15px;
  padding: 5px 15px 5px 15px;
  background-color: lightcyan;
}

.enable-user-button {
  background-color: green !important;
  color: white;
  font-weight: bold;
}

.disable-user-button {
  background-color: red !important;
  color: white;
  font-weight: bold;
}

.close {
  color: #aaaaaa;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.result {
  text-align: center;
  font-weight: bold;
  font-size: large;
}

.result .success {
  color: green;
}

.result .danger {
  color: red;
}

.enabled-cell {
    background-color: green;
    color: white;
    font-weight: bold;
}

.disabled-cell {
    background-color: red;
    color: white;
    font-weight: bold;
}
</style>