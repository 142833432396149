<template>
    <div>
        <main>
            <form @submit.prevent="submitForm">
                <div class="form-group">
                    <small>Nome e cognome:</small>
                    <input type="text" class="form-control" ref="name" placeholder="Nome e cognome" v-model="name" />
                </div>
                <div class="form-group">
                    <small>Email:</small>
                    <input type="text" class="form-control" placeholder="Email" v-model="email" />
                </div>
                <div class="form-group buttons">
                    <input type="button" @click="toggleChangePasswordModal" class="btn btn-secondary btn-sm" value="Cambia Password" />
                    <input type="submit" class="btn btn-primary btn-sm" value="Salva" />
                </div>
            </form>
            <div v-if="isModalChangePasswordOpen" class="change-password-modal">
                <div class="change-password-modal-content">
                    <h2>Cambia password</h2>
                    <form @submit.prevent="handleChangePasswordSubmit">
                        <div class="form-group">
                            <label>Password:</label>
                            <input type="password" class="form-control" required v-model="passwordField1" />
                        </div>
                        <div class="form-group">
                            <label>Conferma password:</label>
                            <input type="password" class="form-control" required v-model="passwordField2" />
                        </div>
                        <div class="form-group buttons">
                            <input type="button" @click="toggleChangePasswordModal" class="btn btn-secondary btn-sm" value="Annulla" />
                            <input type="submit" class="btn btn-primary btn-sm" value="Salva" />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';
export default {

    props: {
        user: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            name: "",
            email: "",
            passwordField1: "",
            passwordField2: "",
            isModalChangePasswordOpen: false
        }
    },
    
    mounted() {
        this.$refs.name.focus();
        this.name = this.user.name;
        this.email = this.user.email;
    },

    methods: {
        updateUI() {
            this.passwordField1 = "",
            this.passwordField2 = ""
        },
        async submitForm() {
            const body = {
                name: this.name,
                email: this.email,
                role: this.user.role
            }
            const response = await request(
                apiConfig.endpoints.userUpdate+"/"+this.user.id,
                apiConfig.methods.put,
                body,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                if(data.message === "OK") {
                    this.user.name = this.name
                    this.user.email = this.email
                    showNotification("Utente modificato con successo.", "success", 3000);
                } else {
                    showNotification(data.message, "error", 3000);
                    this.updateUI();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async handleChangePasswordSubmit() {
            const id = this.user.id;
            const password1 = this.passwordField1;
            const password2 = this.passwordField2;
            // Verifica che tutti i campi siano compilati
            if (!password1 || !password2) {
                showNotification('Compila tutti i campi prima di continuare.', "warning", 4000);
                return;
            }
            if (password1 !== password2) {
                showNotification('Le password indicate sono diverse, riprova.', "warning", 4000);
                return;
            }
            const requestBody = {
                password1,
                password2
            };
            const changePasswordEndpoint = apiConfig.endpoints.changePasswordUser+'/'+id;
            const response = await request(changePasswordEndpoint, apiConfig.methods.post, requestBody, store.getToken());
            if (response.ok) {
                showNotification("La password è stata cambiata correttamente.", "success", 4000);
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
            this.updateUI();
            this.toggleChangePasswordModal();
        },
        toggleChangePasswordModal() {
            this.isModalChangePasswordOpen = !this.isModalChangePasswordOpen;
        }

    },
    

}

</script>

<style scoped>
    form {
        padding: 15px 0 15px 0;
    }
    button {
        margin-right: 10px;
    }
    .buttons > input {
        display: inline-flex;
        margin-right: 5px;
    }
    .change-password-modal {
        display: block; 
        position: fixed;
        z-index: 110; 
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%; 
        overflow: auto; 
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }
    .change-password-modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 40%;
    }
    .close {
        color: #aaaaaa;
        text-align: right;
        font-size: 28px;
        font-weight: bold;
    }
    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
</style>