<template>
    <div>
        <main>
            <form @submit.prevent="submitForm">
                <div class="form-group">
                    <small>Titolo</small>
                    <select v-model="title" class="form-select">
                        <option v-for="(title) in titles" :key="title" :value="title">{{ title }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <small>Indirizzo</small>
                    <input type="text" class="form-control" ref="address" placeholder="Indirizzo" v-model="address" />
                </div>
                <div class="form-group">
                    <small>Numero civico</small>
                    <input type="text" class="form-control" placeholder="Numero civico" v-model="number" />
                </div>
                <div class="form-group">
                    <small>CAP</small>
                    <input type="text" class="form-control" placeholder="CAP" v-model="cap" />
                </div>
                <div class="form-group">
                    <small>Città o paese (nome esteso)</small>
                    <input type="text" class="form-control" placeholder="Città o paese (nome esteso)" v-model="city" />
                </div>
                <div class="form-group">
                    <small>Provincia</small>
                    <select v-model="code" class="form-select">
                        <option value="" selected>Seleziona una provincia</option>
                        <option v-for="(name, code) in provinces" :key="code" :value="code">{{ name }} ({{ code }})</option>
                    </select>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-sm">Salva</button>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';
import { toRefs } from 'vue';

export default {

    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        doctor: {
            type: Object,
            default: () => ({})
        }
    },

    mounted() {
        if(this.doctor) {
            this.title = this.doctor.title,
            this.address = this.doctor.address,
            this.number = this.doctor.number,
            this.cap = this.doctor.cap,
            this.city = this.doctor.city,
            this.code = this.doctor.code
        }
    },

    methods: {
        async submitForm() {
            const body = {
                title: this.title,
                address: this.address,
                number: this.number,
                cap: this.cap,
                city: this.city,
                code: this.code
            };
            const endpoint = apiConfig.endpoints.doctorUpdate + "/" + this.doctor.id;
            const response = await request(endpoint, apiConfig.methods.put, body, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.doctorChanged = data.doctor;
                    this.address = this.doctorChanged.address;
                    this.number = this.doctorChanged.number;
                    this.cap = this.doctorChanged.cap;
                    this.city = this.doctorChanged.city;
                    this.code = this.doctorChanged.code;
                    showNotification("Utente modificato con successo.", "success", 3000);
                } else {
                    showNotification(data.message, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        }
    },  

    data() {        
        return {
            doctorChanged: null,
            title: "",
            address: "",
            number: "",
            cap: "",
            city: "",
            code: "",
            titles: [ 'Prof.', 'Dott.', 'Dott.ssa' ],
            provinces: {
                AG: 'Agrigento',
                AL: 'Alessandria',
                AN: 'Ancona',
                AO: 'Aosta',
                AR: 'Arezzo',
                AP: 'Ascoli Piceno',
                AT: 'Asti',
                AV: 'Avellino',
                BA: 'Bari',
                BT: 'Barletta-Andria-Trani',
                BL: 'Belluno',
                BN: 'Benevento',
                BG: 'Bergamo',
                BI: 'Biella',
                BO: 'Bologna',
                BZ: 'Bolzano',
                BS: 'Brescia',
                BR: 'Brindisi',
                CA: 'Cagliari',
                CL: 'Caltanissetta',
                CB: 'Campobasso',
                CI: 'Carbonia-Iglesias',
                CE: 'Caserta',
                CT: 'Catania',
                CZ: 'Catanzaro',
                CH: 'Chieti',
                CO: 'Como',
                CS: 'Cosenza',
                CR: 'Cremona',
                KR: 'Crotone',
                CN: 'Cuneo',
                EN: 'Enna',
                FM: 'Fermo',
                FE: 'Ferrara',
                FI: 'Firenze',
                FG: 'Foggia',
                FC: 'Forlì-Cesena',
                FR: 'Frosinone',
                GE: 'Genova',
                GO: 'Gorizia',
                GR: 'Grosseto',
                IM: 'Imperia',
                IS: 'Isernia',
                SP: 'La Spezia',
                AQ: 'L\'Aquila',
                LT: 'Latina',
                LE: 'Lecce',
                LC: 'Lecco',
                LI: 'Livorno',
                LO: 'Lodi',
                LU: 'Lucca',
                MC: 'Macerata',
                MN: 'Mantova',
                MS: 'Massa-Carrara',
                MT: 'Matera',
                VS: 'Medio Campidano',
                ME: 'Messina',
                MI: 'Milano',
                MO: 'Modena',
                MB: 'Monza e Brianza',
                NA: 'Napoli',
                NO: 'Novara',
                NU: 'Nuoro',
                OG: 'Ogliastra',
                OT: 'Olbia-Tempio',
                OR: 'Oristano',
                PD: 'Padova',
                PA: 'Palermo',
                PR: 'Parma',
                PV: 'Pavia',
                PG: 'Perugia',
                PU: 'Pesaro e Urbino',
                PE: 'Pescara',
                PC: 'Piacenza',
                PI: 'Pisa',
                PT: 'Pistoia',
                PN: 'Pordenone',
                PZ: 'Potenza',
                PO: 'Prato',
                RG: 'Ragusa',
                RA: 'Ravenna',
                RC: 'Reggio Calabria',
                RE: 'Reggio Emilia',
                RI: 'Rieti',
                RN: 'Rimini',
                RM: 'Roma',
                RO: 'Rovigo',
                SA: 'Salerno',
                SS: 'Sassari',
                SV: 'Savona',
                SI: 'Siena',
                SR: 'Siracusa',
                SO: 'Sondrio',
                TA: 'Taranto',
                TE: 'Teramo',
                TR: 'Terni',
                TO: 'Torino',
                TP: 'Trapani',
                TN: 'Trento',
                TV: 'Treviso',
                TS: 'Trieste',
                UD: 'Udine',
                VA: 'Varese',
                VE: 'Venezia',
                VB: 'Verbano-Cusio-Ossola',
                VC: 'Vercelli',
                VR: 'Verona',
                VV: 'Vibo Valentia',
                VI: 'Vicenza',
                VT: 'Viterbo',
            },
        }

    }

}
</script>

<style scoped>
    form {
        padding: 15px 0 15px 0;
    }   
</style>