<template>
  <div>
    <div class="admin-menu">
        <a href="/" class="admin-menu-item">Vai al sito</a> 
        <a href="#" class="admin-menu-item" @click="handleClick('user')" v-if="user.role == 'admin'">Gestione utenti</a> 
        <a href="#" class="admin-menu-item" @click="handleClick('files')" v-if="user.role == 'admin' || user.role == 'editor'">Gestione files</a> 
        <a href="#" class="admin-menu-item" @click="handleClick('magazine')" v-if="user.role == 'admin' || user.role == 'editor'">Gestione riviste</a> 
        <a href="#" class="admin-menu-last-item" @click="logout()">Esci</a>
    </div>
  </div>
</template>

<script>

import store from "@/store";
import apiConfig from '@/config/api';
import { request, responseKO } from '@/config/api';

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleClick(value) {
            this.$emit('menu-click', value);
        },
        async logout() {
            await request(apiConfig.noAuthEndpoints.logout, apiConfig.methods.post, null, store.getToken());
            responseKO();
        }
    }
}
</script>

<style scoped>
.admin-menu {
    margin-top: 15px;
    text-align: center;
}

.admin-menu-item {
    font-size: large;
    padding-right: 5px;
    padding-left: 5px;
    border-right: 2px solid #00a897;
}

.admin-menu-last-item {
    font-size: large;
    padding-right: 5px;
    padding-left: 5px;
}
</style>