<template>
  <div class="footer">
    <footer>
      <div class="container margin_60_35">
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <p>
              <a href="#">
                <img
                  src="/img/logo.png"
                  alt=""
                  width="163"
                  height="36"
                  class="img-fluid"
                /> </a
              ><br /><br />
              Magazine di intrattenimento sulla chirurgia estetica, con focus su
              benessere, salute e lifestyle. Video interviste, dimostrazioni,
              pratiche, livestream
            </p>
          </div>
          <div class="col-lg-3 col-md-4">
            <h5>Sitemap</h5>
            <ul class="links">
              <li><a href="">Homepage</a></li>
              <li><a href="">Chi siamo</a></li>
              <li><a href="">Magazine</a></li>
              <li><a href="">I nostri medici</a></li>
              <li><a href="">Contattaci</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4">
            <h5>Partners</h5>
            <ul class="links">
              <li><a href="">Medical Branding</a></li>
              <li><a href="">Assomedicom</a></li>
              <li><a href="">Mediaverso SRL</a></li>
              <li><a href="">Italiainfarmacia.it</a></li>
              <li><a href="">Oncoestetica</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4">
            <h5>Contattaci</h5>
            <ul class="contacts">
              <li>
                <a href="tel:0689718659"
                  ><i class="icon_mobile"></i> 0689718659</a
                >
              </li>
              <li>
                <a href="mailto:posta@infoestetica.it"
                  ><i class="icon_mail_alt"></i> posta@infoestetica.it</a
                >
              </li>
            </ul>
            <div class="follow_us">
              <h5>Seguici</h5>
              <ul>
                <li>
                  <a href=""><i class="social_facebook"></i></a>
                </li>
                <li>
                  <a href=""><i class="social_instagram"></i></a>
                </li>
                <li>
                  <a href=""><i class="social_youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr />
        <div class="row">
          <div class="col-md-8">
            <ul id="additional_links">
              <li><a @click="onOpenModal('privacy')">Info Privacy</a></li>
              <li><a @click="onOpenModal('cookiePolicy')">Cookie Policy</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <div id="copy">© {{ year }} Infoestetica</div>
          </div>
        </div>
      </div>
    </footer>

    <div id="toTop"></div>

    <div class="modal" v-if="isModalVisibile">
      <div class="modal-content">
        <span class="close" @click="onModalClose">&times;</span>
        <p>{{ modalText }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      year: "",
      isModalVisibile: false,
      modalText: null
    }
  },
  methods: {
    onOpenModal(type) {
      if(type === "cookiePolicy") {
        this.modalText = "Il nostro sito web è impegnato a garantire la tua privacy e la trasparenza nell'uso dei tuoi dati. Vogliamo informarti che il nostro sito non utilizza cookie per scopi commerciali o di profilazione. L'uso dei cookie è strettamente limitato a quello necessario per il funzionamento tecnico del portale e per migliorare la tua esperienza di navigazione. I cookie che utilizziamo sono essenziali per consentire la navigazione sul sito e l'utilizzo delle sue funzionalità, come l'accesso a aree protette. Questi cookie non raccolgono informazioni su di te che possano essere utilizzate per scopi di marketing né tengono traccia della tua navigazione su internet. La nostra priorità è fornirti un servizio sicuro e affidabile, rispettando la tua privacy e le tue preferenze. Se hai domande o preoccupazioni riguardo l'uso dei cookie sul nostro sito, non esitare a contattarci per ulteriori informazioni.";
      } else {
        this.modalText = "La privacy dei nostri utenti è al centro dei valori del nostro portale. Siamo profondamente impegnati a proteggere le informazioni personali e a garantire il massimo livello di sicurezza e riservatezza per tutti coloro che scelgono di utilizzare i nostri servizi. Riconosciamo l'importanza di proteggere la privacy e adottiamo misure rigorose per salvaguardare i dati personali contro accessi non autorizzati, alterazioni, divulgazioni o distruzioni. Trattiamo tutte le informazioni personali nel rispetto delle leggi vigenti sulla protezione dei dati, come il GDPR (Regolamento Generale sulla Protezione dei Dati) per gli utenti dell'Unione Europea. Le informazioni raccolte tramite il nostro portale sono utilizzate esclusivamente per fornire e migliorare i servizi offerti, facilitare la gestione degli account utente, e migliorare l'esperienza complessiva sul sito. Non condividiamo, vendiamo o noleggiamo le tue informazioni personali a terze parti per scopi commerciali senza il tuo esplicito consenso. Rispettiamo la tua privacy e ti forniamo il controllo sui tuoi dati personali, offrendoti la possibilità di accedere, correggere o eliminare le tue informazioni in qualsiasi momento. La nostra politica sulla privacy dettaglia come raccogliamo, utilizziamo e proteggiamo le tue informazioni e come puoi esercitare i tuoi diritti in relazione ai tuoi dati personali. Per qualsiasi domanda o preoccupazione riguardante la privacy e la sicurezza delle tue informazioni personali, ti invitiamo a contattarci direttamente. Il nostro team è a disposizione per fornirti tutte le informazioni necessarie e per assisterti nel garantire la tutela della tua privacy.";
      }
      this.isModalVisibile = true;
    },
    onModalClose() {
      this.isModalVisibile = false;
    },
    updateYear() {
      const date = new Date();
      this.year = date.getFullYear();
    }
  },
  mounted() {
    this.updateYear();
    var $toTop = $("#toTop");
    $(window).on("scroll", function () {
      if ($(this).scrollTop() != 0) {
        $toTop.fadeIn();
      } else {
        $toTop.fadeOut();
      }
    });
    $toTop.on("click", function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        500
      );
    });
  }
};
</script>

<style scoped>
.footer {
  text-align: left;
}
.modal {
  display: block;
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  overflow-y: auto;
  /* Aggiunto per abilitare scrollbar verticale */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 15px;
  border: 1px solid #888;
  width: 60%;
  max-height: 90%;
  overflow-y: auto;
  text-align: justify;
  /* Aggiunto per abilitare scrollbar verticale */
}

.close {
  cursor: pointer;
  display: block;
  z-index: 200px;
  color: #515151;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
}
</style>