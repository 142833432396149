
<template>
  <div>
<!--     <Header /> -->
    <main>
      <div class="bg_color_2">
        <div class="container margin_60_35">
          <div id="login-2" class="myLoginClass">
            <div class="col-md-12 logone-login">
              <img src="/img/ie-fav.png" />
            </div>
            <h1>Fai il login a Infoestetica!</h1>
            <div class="box_form clearfix">
              <!-- <div class="box_login">
                <a href="" class="social_bt facebook">Login con Facebook</a>
                <a href="" class="social_bt google">Login con Google</a>
              </div> -->
              <form @submit.prevent="login">
                <div class="box_login last">
                  <div class="form-group">
                    <input type="text" id="email" class="form-control" placeholder="Indirizzo email" ref="emailInput" v-model="email"
                    />
                  </div>
                  <div class="form-group">
                    <input type="password" id="password" class="form-control" placeholder="Password" v-model="password" />
                    <a @click="recovery" class="forgot"><small>Password dimenticata?</small></a>
                  </div>
                  <div class="form-group">
                    <button class="btn_1">Entra</button>
                  </div>
                </div>
              </form>
            </div>

            <p class="text-center link_bright">
              Non hai ancora un account?
              <a href="/signup"><strong>Registrati!</strong></a>
            </p>
          </div>
        </div>
      </div>
      <div class="modal" v-if="isRecoveryModalVisible">
        <div class="modal-content">
          <span class="close" @click="recoveryToggle">&times;</span>
          <div class="form-group">
            <label>Indica l'email dell'utente che si richiede il cambio della password</label>
            <input type="text" class="form-control" ref="recoveryField" />
          </div>
          <div class="form-group">
            <button class="btn_1" @click="sendRequestRecovery">OK</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import apiConfig from '@/config/api';
import { request, responseKO, redirectToHome } from '@/config/api';
import store from '@/store';

export default {
  name: "LoginView",
  components: {
    Header,
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
  data() {
    return {
      email: "",
      password: "",
      isRecoveryModalVisible: false
    };
  },
  methods: {
    async login() {
      const body = {
        email: this.email,
        password: this.password
      }
      const response = await request(apiConfig.noAuthEndpoints.login, apiConfig.methods.post, body, store.getToken());
      if (response.ok) {
        const data = await response.json();
        store.saveToken(data.access_token);
        redirectToHome();
      } else {
        showNotification("Utente non trovato.", "error", 3500);
      }
    },
    recovery() {
      this.isRecoveryModalVisible = true;
      // Aspetta il prossimo ciclo di aggiornamento del DOM
      this.$nextTick(() => {
        if(this.$refs.recoveryField) {
          this.$refs.recoveryField.focus();
        }
      });
    },
    recoveryToggle() {
      this.isRecoveryModalVisible = !this.isRecoveryModalVisible;
    },
    async sendRequestRecovery() {
      if(this.$refs.recoveryField.value.length > 0) {
        const body = {
          email: this.$refs.recoveryField.value
        }
        const response = await request(apiConfig.noAuthEndpoints.recoveryPassword, apiConfig.methods.post, body, null);
        if (response.ok) {
          const data = await response.json();
          if (data.message === "OK") {
            showNotification("Email inviata. Se non la trovi guarda nella cartella dello SPAM (posta indesiderata). Si prega di eseguire le istruzioni indicate.", "success", 5000);
          } else {
            showNotification(apiConfig.errors.generic, "error", 3000);
          }
          document.location.href = "/";   
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification("Attenzione: il campo è vuoto", "warning", 3000);
        this.$refs.recoveryField.focus();
      }
    }

  }
};
</script>

<style scoped>
.box_login {
  text-align: center;
  width: 100%;
}

.myLoginClass {
  margin: auto !important;
}

/* stile per il modal del recupero password */
.modal {
  display: block; 
  position: fixed;
  z-index: 100; 
  padding-top: 15%;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.6); 
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
}

.close {
  cursor: pointer;
  display: block;
  z-index: 200px;
  color: #515151;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
}

a {
  cursor: pointer;
}
</style>