import store from '@/store';

//const baseUrl = 'https://www.cutalab.it/backend/public/api';
const baseUrl = 'https://medici.infoestetica.it/backend/public/api';
//const baseUrl = 'http://localhost:8000/public/api';

//const storageUrl = 'https://www.cutalab.it/backend/public/';
const storageUrl = 'https://medici.infoestetica.it/backend/public/';
//const storageUrl = 'http://localhost:8000/public/';

//const fileManagerStorageUrl = 'https://www.cutalab.it/backend/storage/';
const fileManagerStorageUrl = 'https://medici.infoestetica.it/backend/storage/';
//const fileManagerStorageUrl = 'http://localhost:8000/storage/';

const apiConfig = {
  errors: {
    login: "Si è verificato un errore in fase di login.",
    logout: "Si è verificato un errore in fase di logout.",
    request: "Richiesta fallita.",
    generic: "Errore generico."
  },
  endpoints: {
    userList: baseUrl+"/admin/user/list",
    userCreate: baseUrl+"/admin/user/create",
    userUpdate: baseUrl+"/admin/user/update",
    userEnable: baseUrl+"/admin/user/enable",
    userDisable: baseUrl+"/admin/user/disable",
    changePasswordUser: baseUrl+"/admin/user/password",
    editionCreate: baseUrl+"/admin/edition/create",
    editionUpdate: baseUrl+"/admin/edition/update",
    editionList: baseUrl+"/admin/edition/list",
    articleCreate: baseUrl+"/admin/article/create",
    articleUpdate: baseUrl+"/admin/article/update",
    articleDelete: baseUrl+"/admin/article/delete",
    articleList: baseUrl+"/admin/article/list",
    articleRead: baseUrl+"/admin/article/read",
    filemanagerCreateFolder: baseUrl+"/filemanager/create/folder",
    filemanagerReadFolder: baseUrl+"/filemanager/read",
    filemanagerUploadFile: baseUrl+"/filemanager/upload",
    agendaCreate: baseUrl+"/agenda/create",
    agendaRead: baseUrl+"/agenda/read",
    agendaDelete: baseUrl+"/agenda/delete",
    doctorUpdate: baseUrl+"/doctor/update",
    doctorUpdateDoctorTag: baseUrl+"/doctor/updateDoctorTag",
    appointmentCreate: baseUrl+"/appointment/create",
    appointmentList: baseUrl+"/appointment/list",
    appointmentListDoctorIdAndDate: baseUrl+"/appointment/doctor-list",
    appointmentDeleteAll: baseUrl+"/appointment/delete-all",
    doctorTagsList: baseUrl+"/doctor/tag/list"
  },
  noAuthEndpoints: {
    login: baseUrl+"/login",
    logout: baseUrl+"/logout",
    me: baseUrl+"/me",
    recoveryPassword: baseUrl+"/recovery",
    recoveryChangePassword: baseUrl+"/recovery/change-password",
    editionReadByMonthYear: baseUrl+"/edition/read-by-month-year",
    editionArticles: baseUrl+"/edition/article-list",
    editionArticle: baseUrl+"/edition/article",
    editionMonthsYearsList: baseUrl+"/edition/month-list",
    doctorList: baseUrl+"/doctors",
    doctor: baseUrl+"/doctor",
    doctorByUserId: baseUrl+"/doctor-by-user-id",
    doctorServices: baseUrl+"/doctor/services",
    agendaReadMonth: baseUrl+"/agenda/read-month",
    agendaReadDay: baseUrl+"/agenda/read-day",
    userCreate: baseUrl+"/signup/user/create",
    doctorCreate: baseUrl+"/signup/doctor/create",
    agendaMassiveDelete: baseUrl+"/agenda/massive-delete",
    editorList: baseUrl+"/editor/list",
    editorById: baseUrl+"/editor/find-by-id"
  },
  methods: {
    'get': 'GET',
    'post': 'POST',
    'put': 'PUT',
    'delete': 'DELETE'
  },
  genericHeader: {
    'Content-Type': 'application/json'
  },
  authHeaders: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+store.getToken()
  },
  get storageUrl() {
    return storageUrl;
  },
  get fileManagerStorageUrl() {
    return fileManagerStorageUrl;
  },
};

function removeToken() {
  store.removeToken();
}

export default apiConfig;
export function redirectToHome() {
  window.location.href = "/";
}
export function responseKO() {
  removeToken();
  redirectToHome();
}
export async function request(rEndpoint, rMethod, rBody, rToken) {
  var response = null;
  try {
    $('#preloader').show();
    const requestOptions = {
      method: rMethod,
      headers: rToken !== null ? apiConfig.authHeaders : apiConfig.genericHeader,
    };
    if (rBody !== null) {
      requestOptions.body = JSON.stringify(rBody);
    }
    response = await fetch(rEndpoint, requestOptions);
    if(response === null) alert("null")
  } catch(error) {
    showNotification(apiConfig.errors.generic, "error", 3000);
    removeToken();
    redirectToHome();
  } finally {
    $('#preloader').hide();
  }
  return response;
}

export async function requestWihtoutPreload(rEndpoint, rMethod, rBody, rToken) {
  var response = null;
  try {
    const requestOptions = {
      method: rMethod,
      headers: rToken !== null ? apiConfig.authHeaders : apiConfig.genericHeader,
    };
    if (rBody !== null) {
      requestOptions.body = JSON.stringify(rBody);
    }
    response = await fetch(rEndpoint, requestOptions);
    if(response === null) alert("null")
  } catch(error) {
    showNotification(apiConfig.errors.generic, "error", 3000);
    removeToken();
    redirectToHome();
  }
  return response;
}

export async function requestWithFormData(rEndpoint, rMethod, rFormData, rToken) {
  var response = null;
  try {
    $('#preloader').show();
    const boundary = Date.now().toString(16);
    rFormData.append(`--${boundary}--`);
    const requestOptions = {
      method: rMethod,
      headers: rToken !== null ? apiConfig.authHeaders : apiConfig.genericHeader,
      body: rFormData,
    };
    response = await fetch(rEndpoint, requestOptions);
  } catch(error) {
    showNotification(apiConfig.errors.generic, "error", 3000);
    removeToken();
    redirectToHome();
  } finally {
    $('#preloader').hide();
  }
  return response;
}