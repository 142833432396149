import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import MagazineView from '../views/MagazineView.vue'
import DoctorsView from '../views/DoctorsView.vue'
import DoctorView from '../views/DoctorView.vue'
import AdminView from '../views/AdminView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import EditionView from '../views/EditionView.vue';
import ArticleView from '../views/ArticleView.vue';
import RecoveryView from '../views/RecoveryView.vue';
import ProfileView from '../views/ProfileView.vue';
import AppointmentCreatedView from '../views/AppointmentCreatedView.vue';


const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: NotFoundView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/magazine',
    name: 'magazine',
    component: MagazineView
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorsView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/edition/:month/:year',
    name: 'edition',
    component: EditionView
  },
  {
    path: '/article/:id/:year/:month',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/doctor/:id',
    name: 'doctor',
    component: DoctorView
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: RecoveryView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView
  },
  {
    path: '/appointment-created',
    name: 'AppointmentCreated',
    component: AppointmentCreatedView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
