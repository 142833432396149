<template>
  <div style="margin-bottom: 80px">
    <div class="hero_home hero_kenburns" style="z-index: 999">
      <div class="content">
        <div class="col-md-12 logone-login">
          <img src="img/ie-fav.png" />
        </div>
        <h3>Infoestetica</h3>
        <p>
          Il portale di Chirurgia Plastica e Medicina Estetica dedicato a medici e
          pazienti
        </p>

        <!-- form prima-->

      </div>
    </div>
    <div style="z-index: 1000; margin-top: -170px; position: relative;">
      <div id="custom-search-input">
        <div class="input-group">
          <input type="text" class="search-query" placeholder="Nome, Città, Trattamento ...." v-model="searchText" 
            @keyup.enter="search" />
          <input type="submit" class="btn_search" value="Cerca" @click="search" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: ''
    }
  },
  methods: {
    search() {
      this.$router.push({ path: '/doctors/', query: { searchText: this.searchText } });
    }
  }
};

</script>

<style></style>