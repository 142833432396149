import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueTelInput from 'vue-tel-input';
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App);
app.use(router);
app.use(VueTelInput);
app.use(VueReCaptcha, { 
    //siteKey: '6Ldl5oUpAAAAANZr31_F6VDlWqWTNuKD12H35vfa'
    siteKey: '6LeGbKwpAAAAAFujI49kV5ETxsSW_MgxH_S9S_ez'
});
app.mount('#app');