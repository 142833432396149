<template>
    <div class="editor-container">
        <div class="editor-toolbar">
            <button type="button" @click="execCommand('bold')" class="menu-item" title="Bold"><i
                    class="fa-solid fa-bold"></i></button>
            <button type="button" @click="execCommand('italic')" class="menu-item" title="Italic"><i
                    class="fa-solid fa-italic"></i></button>
            <button type="button" @click="execCommand('underline')" class="menu-item" title="Underline"><i
                    class="fa-solid fa-underline"></i></button>
            <button type="button" @click="execCommand('insertOrderedList')" class="menu-item" title="Numbered List"><i
                    class="fa-solid fa-list-ol"></i></button>
            <button type="button" @click="execCommand('insertUnorderedList')" class="menu-item" title="Bullet List"><i
                    class="fa-solid fa-list"></i></button>
            <button type="button" @click="execCommand('justifyLeft')" class="menu-item" title="Align Left"><i
                    class="fa-solid fa-align-left"></i></button>
            <button type="button" @click="execCommand('justifyCenter')" class="menu-item" title="Align Center"><i
                    class="fa-solid fa-align-center"></i></button>
            <button type="button" @click="execCommand('justifyRight')" class="menu-item" title="Align Right"><i
                    class="fa-solid fa-align-right"></i></button>
            <button type="button" @click="execCommand('justifyFull')" class="menu-item" title="Align Justify"><i
                    class="fa-solid fa-align-justify"></i></button>
            <button type="button" @click="wrapSelectedText('p')" class="menu-item" title="Paragraph"><i
                    class="fa-solid fa-paragraph"></i></button>
            <button type="button" @click="wrapSelectedText('h1')" class="menu-item" title="Heading 1"><i
                    class="fa-solid fa-heading">1</i></button>
            <button type="button" @click="wrapSelectedText('h2')" class="menu-item" title="Heading 2"><i
                    class="fa-solid fa-heading">2</i></button>
            <button type="button" @click="wrapSelectedText('h3')" class="menu-item" title="Heading 3"><i
                    class="fa-solid fa-heading">3</i></button>
            <button type="button" @click="openColorModal()" class="menu-item" title="Insert colored text"><i
                    class="fa-solid fa-paint-roller"></i></button>
            <button type="button" @click="openLinkModal()" class="menu-item" title="Insert Link"><i
                    class="fa-solid fa-link"></i></button>
            <button type="button" @click="removeLink()" class="menu-item" title="Remove Link"><i
                    class="fa-solid fa-link-slash"></i></button>
            <button type="button" @click="openImageModal()" class="menu-item" title="Insert Image"><i
                    class="fa-regular fa-image"></i></button>
        </div>
        <div class="editor-content" contenteditable="true" id="editor" @input="updateHtmlCode"
            @click="updateCursorPosition">
        </div>
        <div class="html-container">
            <h3>HTML Code</h3>
            <textarea id="html-code" rows="10" style="width: 100%;" @input="updateEditorContent()"
                v-model="internalValue"></textarea>
        </div>

        <!-- Modal per l'inserimento del link -->
        <div id="linkModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeLinkModal()">&times;</span>
                <div class="modal-content-item">
                    <label for="linkUrl">Testo:</label>
                    <input type="text" id="linkText">
                </div>
                <div class="modal-content-item">
                    <label for="linkUrl">URL:</label>
                    <input type="text" id="linkUrl">
                </div>
                <div class="modal-content-item">
                    <label for="linkTarget">Target:</label>
                    <select id="linkTarget">
                        <option value="_self">Self</option>
                        <option value="_blank">Blank</option>
                    </select>
                </div>
                <div class="modal-content-item">
                    <button type="button" @click="insertLink()">Inserisci link</button>
                </div>
            </div>
        </div>

        <!-- Modal per l'inserimento dell'immagine -->
        <div id="imageModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeImageModal()">&times;</span>
                <div class="modal-content-item">
                    <label for="imageUrl">Immagine URL:</label>
                    <input type="text" id="imageUrl">
                </div>
                <div class="modal-content-item">
                    <label>Larghezza/Altezza:</label>
                    <select id="modifyOption" @change="handleModifyOption()">
                        <option value="both">Entrambe</option>
                        <option value="width">Larghezza</option>
                        <option value="height">Altezza</option>
                    </select>
                </div>
                <div class="modal-content-item" id="imageWidthDiv">
                    <label for="imageWidth">Larghezza (200px o lascia vuoto):</label>
                    <input type="text" id="imageWidth">
                </div>
                <div class="modal-content-item" id="imageHeightDiv">
                    <label for="imageHeight">Altezza (200px o lascia vuoto):</label>
                    <input type="text" id="imageHeight">
                </div>
                <div class="modal-content-item">
                    <label for="imageAlign">Allineamento:</label>
                    <select id="imageAlign">
                        <option value="left">Sinistra</option>
                        <option value="right">Destra</option>
                    </select>
                </div>
                <div class="modal-content-item">
                    <label for="imageMargin">Margine (15px 15px 15px 15px):</label>
                    <input type="text" id="imageMargin">
                </div>
                <div class="modal-content-item">
                    <button type="button" @click="insertImage()">Inserisci immagine</button>
                </div>
            </div>
        </div>
        <!-- Modal per l'inserimento del colore -->
        <div id="colorModal" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeColorModal()">&times;</span>
                <table style="width: 100%">
                    <tr>
                        <td style="width: 95%; padding-right: 15px"><input type="text" id="colorText" placeholder="Testo" style="width: 100%">
                        </td>
                        <td style="width: 5%"><input type="color" id="color" style="height: 30px !important" /></td>
                    </tr>
                </table>
                <div class="modal-content-item">
                    <button type="button" @click="insertColor()" class="btn btn-light">Inserisci testo</button>
                </div>
            </div>
        </div>
    </div>        
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            cursorPosition: null,
            internalValue: this.value
        }
    },
    watch: {
        // Aggiorna il valore interno quando il valore esterno cambia
        value(newVal) {
            this.internalValue = newVal;
        },
        // Notifica il genitore quando il valore interno cambia
        internalValue(newVal) {
            this.$emit('input', newVal);
        }
    },
    mounted() {
        this.updateEditorContent();
    },
    methods: {
        execCommand(command, value = null) {
            document.execCommand(command, false, value);
            this.updateHtmlCode();
        },
        wrapSelectedText(tag) {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const selectedText = range.extractContents();
            const wrapper = document.createElement(tag);
            // Trova il tag di intestazione nella gerarchia DOM
            let parentNode = range.commonAncestorContainer;
            while (parentNode && !parentNode.nodeName.match(/^(H1|H2|H3)$/)) {
                parentNode = parentNode.parentNode;
            }
            if (parentNode) {
                // Rimuovi il tag di intestazione esistente
                const parent = parentNode.parentNode;
                while (parentNode.firstChild) {
                    parent.insertBefore(parentNode.firstChild, parentNode);
                }
                parent.removeChild(parentNode);
            }
            wrapper.appendChild(selectedText);
            range.insertNode(wrapper);
            this.updateHtmlCode();
        },
        openImageModal() {
            // Verifica se il cursore è presente nell'editor
            const editor = document.getElementById('editor');
            const selection = window.getSelection();
            if (!editor.contains(selection.anchorNode)) {
                showNotification("Seleziona il punto dell\'editor dove vuoi che venga inserito il componente.", "warning", 4000);
                return;
            }
            this.cursorPosition = this.saveSelection();
            document.getElementById('imageModal').style.display = 'block';
        },
        closeImageModal() {
            document.getElementById('imageModal').style.display = 'none';
        },
        handleModifyOption() {
            const modifyOption = document.getElementById('modifyOption').value;
            const widthInput = document.getElementById('imageWidthDiv');
            const heightInput = document.getElementById('imageHeightDiv');
            if (modifyOption === 'both') {
                widthInput.style.display = 'block';
                heightInput.style.display = 'block';
            } else if (modifyOption === 'width') {
                widthInput.style.display = 'block';
                heightInput.style.display = 'none';
                heightInput.value = '';
            } else if (modifyOption === 'height') {
                widthInput.style.display = 'none';
                heightInput.style.display = 'block';
                widthInput.value = '';
            }
        },
        insertImage() {
            const imageUrl = document.getElementById('imageUrl').value;
            const imageWidth = document.getElementById('imageWidth').value;
            const imageHeight = document.getElementById('imageHeight').value;
            const imageAlign = document.getElementById('imageAlign').value;
            const imageMargin = document.getElementById('imageMargin').value;
            let imageStyle = '';
            if (imageWidth) {
                imageStyle += `width: ${imageWidth}; `;
            }
            if (imageHeight) {
                imageStyle += `height: ${imageHeight}; `;
            }
            if (imageMargin) {
                imageStyle += `margin: ${imageMargin}; `;
            }
            const imageTag = `<img src="${imageUrl}" style="${imageStyle}" align="${imageAlign}">`;
            this.restoreSelection(this.cursorPosition);
            document.execCommand('insertHTML', false, imageTag);
            this.closeImageModal();
            this.updateHtmlCode();
        },
        updateHtmlCode() {
            const editorContent = document.getElementById('editor').innerHTML;
            document.getElementById('html-code').value = editorContent;
            this.$emit('html-code-updated', editorContent);
        },
        updateEditorContent() {
            const htmlCode = document.getElementById('html-code').value;
            document.getElementById('editor').innerHTML = htmlCode;
        },
        handleEnterKey() {
            this.updateHtmlCode();
        },
        saveSelection() {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                return selection.getRangeAt(0);
            }
            return null;
        },
        restoreSelection(range) {
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        },
        updateCursorPosition() {
            this.cursorPosition = this.saveSelection();
        },
        openLinkModal() {
            // Verifica se il cursore è presente nell'editor
            const editor = document.getElementById('editor');
            const selection = window.getSelection();
            if (!editor.contains(selection.anchorNode)) {
                showNotification("Seleziona il punto dell'editor dove vuoi che venga inserito il componente.", "primary", 4000);
                return;
            }
            document.getElementById('linkText').value = '';  // Pulisce il campo del testo
            document.getElementById('linkUrl').value = '';   // Pulisce il campo dell'URL
            document.getElementById('linkTarget').value = '_blank';  // Imposta il target predefinito
            document.getElementById('linkModal').style.display = 'block';  // Apre il modal
        },
        openColorModal() {
            // Verifica se il cursore è presente nell'editor
            const editor = document.getElementById('editor');
            const selection = window.getSelection();
            if (!editor.contains(selection.anchorNode)) {
                showNotification("Seleziona il punto dell'editor dove vuoi che venga inserito il componente.", "primary", 4000);
                return;
            }
            document.getElementById('colorText').value = '';
            document.getElementById('color').value = '#000000';
            document.getElementById('colorModal').style.display = 'block';
        },
        insertColor() {
            // Recupera i valori da inserire
            const linkText = document.getElementById('colorText').value;
            const linkColor = document.getElementById('color').value;
            // Costruisci il tag 'div'
            const linkTag = `<div style='color: ${linkColor}'>${linkText}</div>\r\n`;
            this.restoreSelection(this.cursorPosition);
            document.execCommand('insertHTML', false, linkTag);
            // Chiudi il modal
            this.closeColorModal();
        },
        insertLink() {
            // Recupera i valori da inserire
            const linkText = document.getElementById('linkText').value;
            const linkUrl = document.getElementById('linkUrl').value;
            const linkTarget = document.getElementById('linkTarget').value;
            // Costruisci il tag 'a'
            const linkTag = `<a href="${linkUrl}" target="${linkTarget}">${linkText}</a>`;
            // Ripristina la selezione e inserisci il link
            this.restoreSelection(this.cursorPosition);
            document.execCommand('insertHTML', false, linkTag);
            // Chiudi il modal
            this.closeLinkModal();
        },
        removeLink() {
            document.execCommand('unlink');
            this.updateHtmlCode();
        },
        closeLinkModal() {
            // Chiudi il modal
            document.getElementById('linkModal').style.display = 'none';
            // Pulisci i campi del modal
            document.getElementById('linkText').value = '';
            document.getElementById('linkUrl').value = '';
            document.getElementById('linkTarget').value = '_blank';
        },
        closeColorModal() {
            // Chiudi il modal
            document.getElementById('colorModal').style.display = 'none';
            document.getElementById('colorText').value = '';
            document.getElementById('color').value = '#000000';
        }
    }
};
</script>

<style scoped>
.editor-container {
    width: 100%;
    margin: 20px auto;
}

.editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 5px;
}

.editor-toolbar button {
    margin: 5px;
}

.editor-content {
    border: 1px solid #ccc;
    height: 500px;
    overflow-y: auto;
    padding: 10px;
    width: 100%;
    white-space: pre-wrap;
}

.html-container {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
}

.menu-item {
    padding: 15px;
    width: 60px;
    height: 60px;
}

.menu-item>i {
    font-size: 18px;
}

/* Aggiunti stili per il modal */
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    display: grid;
}

.modal-content-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-content-item>input,
label,
select {
    width: 100%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>