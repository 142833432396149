<template>
    <div>
        <main>
            <div>
                <p class="resultKO" v-if="!appointments">Non hai appuntamenti.</p>
            </div>
            <div v-if="appointments" class="appointments">
                <!-- Cicla la lista di appuntamenti -->
                <div v-for="(appointment, index) in appointments" :key="index" class="appointment-item">
                    <!-- Mostra le informazioni dell'appuntamento -->
                    <p>Data: {{ formatDate(appointment.date) }}</p>
                    <p>Dottore: {{ appointment.doctorName }}</p>
                    <!-- Aggiungi altre informazioni dell'appuntamento se necessario -->
                </div>
            </div>
        </main>
    </div>
</template>

<script>

import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';
export default {

    props: {
        user: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            appointments: null
        }
    },
    
    mounted() {
        this.deletePreviousAppointments();
        this.getAppointments();
    },

    methods: {
        async deletePreviousAppointments() {
            const endpoint = apiConfig.endpoints.appointmentDeleteAll;
            const response = await request(endpoint, apiConfig.methods.delete, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    //non faccio niente
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getAppointments() {
            const endpoint = apiConfig.endpoints.appointmentList+"/"+this.user.id
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.appointments = data.result.appointments; 
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
        }

    }

    

}

</script>

<style scoped>
    .resultKO {
        margin-top: 15px;
        color: red;
        text-align: center;
        font-weight: bold;
    }
    .appointment-item {
        margin-bottom: 10px;
        border: 1px solid #ccc;
        padding: 10px;
    }
    .appointments {
        margin-top: 15px;
    }
</style>