<template>
  <div class="admin-edition-section">
    <div class="menu">
      <button class="menu-button" @click="addEditionDivToggle()">+ aggiungi un edizione</button>
      <button class="menu-button" @click="selectYearDivToggle()">scegli un'edizione</button>
    </div>

    <!-- SELEZIONA ANNO PER SFOGLIARE GLI ARTICOLI-->
    <div v-show="selectYearDiv" v-if="toggleSelectYearDiv" class="select-year">
      <label>Seleziona l'edizione dell'anno:</label><br />
      <VueDatePicker v-model="year" year-picker locale="it" cancelText="Annulla" selectText="Seleziona"></VueDatePicker>
      <div class="d-flex align-items-center" style="height: 80px;">
        <button class="btn btn-light btn-lg mx-auto" @click="searchEdition()">Cerca...</button>
      </div>
    </div>

    <!-- AGGIUNGI UN'EDIZIONE -->
    <div v-show="addEditionDiv" class="add-edition">
      <h2>Nuova edizione</h2>
      <form @submit.prevent="submitEdition" enctype="multipart/form-data">
        <label>Scegli il mese e l'anno:</label>
        <VueDatePicker required v-model="editionDate" month-picker locale="it" cancelText="Annulla" selectText="Seleziona"></VueDatePicker><br />

        <label>Editoriale di:</label><br>
        <select class="form-select" v-model="editorId">
          <option v-for="editor in editors" :key="editor.id" :value="editor.id">
            {{ editor.name }}
          </option>
        </select>

        <div class="d-flex align-items-center" style="height: 80px;">
          <button class="btn btn-link btn-lg mx-auto">Salva</button>
        </div>
      </form>
    </div>
  </div>

  <!-- LISTA EDIZIONI -->
  <div v-show="showEditionsDiv" class="editions-div">
    <table class="editions-table">
      <tbody>
        <tr v-for="(edition, id) in editions" :key="id" @click="handleEditionClick(edition)">
          <td>
            {{ formatDate(edition.date) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- SPAZIO PER STAMPARE I RISULTATI DELLE OPERAZIONI DB -->
  <div v-if="result">
    <p :class="{ 'result': true, 'error': resultWithError }">{{ resultText }}</p>
  </div>

  <!-- EDIZIONE SCELTA -->
  <div v-show="showArticlesDiv" v-if="edition">
      <h2>{{ formatDate(edition.date).toUpperCase() }}</h2><br>
  </div>

  <!-- MODIFICA IMMAGINE COPERTINA DELL'EDIZIONE -->
  <div v-show="showArticlesDiv" v-if="edition" style="padding: 15px; border: 1px solid black; width: 80%; margin-bottom: 20px">
    <h2>Immagine di copertina (edizione)</h2>
      <div>
        <form @submit.prevent="submitIndexImageEdition" style="margin: auto; text-align: center">
          <label>Inserisci il link dell'immagine:</label><br>
          <input type="text" v-model="indexImageEdition" style="width: 640px" />
          <br />
          <button type="submit" class="btn btn-link btn-lg mx-auto">Salva</button>
        </form>
      </div>
  </div>

  <!-- LISTA ARTICOLI -->
  <div v-show="showArticlesDiv" v-if="edition" style="padding: 15px; border: 1px solid black; width: 80%; margin-bottom: 50px">
    <h2>Articoli</h2>
    <div style="text-align: center;"><button @click="openModal('add')">+ aggiungi articolo</button></div>
    <table class="editions-table">
      <thead>
        <th class="action-column" style="width: 80%"></th>
        <th style="width: 10%"></th>
        <th style="width: 10%"></th>
      </thead>
      <tbody>
        <tr v-for="article in articles" :key="article.id_article">
          <td>{{ article.title }}</td>
          <td class="action-column" @click="openModal('edit', article)"><button style="width: 100%">modifica</button></td>
          <td class="action-column" @click="openModal('remove', article)"><button style="width: 100%">rimuovi</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- MODAL PER GESTIRE L'ARTICOLO -->
  <div class="modal" v-if="isModalVisibile">
    <div class="modal-content">
      <span class="close" @click="modalToggle">&times;</span>
      <h3>{{ modalTitle }}</h3>
      <hr />
      <form @submit.prevent="submitArticle" v-show="articleFormVisible" enctype="multipart/form-data">
        <input type="hidden" v-model="articleId" />
        <input type="hidden" v-model="edition.id_edition" />
        <div class="form-group">
          <input class="form-check-input" type="checkbox" value="" v-model="articleInterview" id="row1"
            style="width: 20px; height: 20px">
          <label class="form-check-label" for="row1" style="padding-left: 10px; padding-top: 5px">L'articolo è
            un'intervista?</label>
        </div>
        <div class="form-group">
          <label>Autore:</label><br>
          <select class="form-select" v-model="editorId">
            <option v-for="editor in editors" :key="editor.id" :value="editor.id">
              {{ editor.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-check-label" for="row0" style="padding-left: 10px; padding-top: 5px">Inserisci il link dell'immagine principale dell'articolo:</label><br>
          <input type="text" id="row0" v-model="articleImage" class="form-control">
        </div>
        <div class="form-group">
          <label for="row2">Posizione dell'articolo nell'indice (edizione)</label>
          <input class="form-control" type="number" value="0" v-model="articlePosition" id="row2">
        </div>
        <div class="form-group">
          <label for="row3">Titolo</label>
          <input type="text" class="form-control" id="row3" v-model="articleTitle" placeholder="Inserisci il titolo...">
        </div>
        <div class="form-group">
          <label for="row4">Sottotitolo</label>
          <input type="text" class="form-control" id="row4" v-model="articleSubtitle"
            placeholder="Inserisci il sottotitolo...">
        </div>
        <div class="form-group">
          <label for="row5">Testo</label>
          <Editor :value="articleBody" @input="updateFormValue" @html-code-updated="handleHtmlCodeUpdated" />
        </div>
        <div class="button-container">
            <button type="button" class="btn btn-secondary" @click="modalToggle">Annulla</button>
            <button type="submit" class="btn btn-primary">Salva</button>
        </div>
      </form>

      <div v-show="articleRemoveDivVisible">
        <p>Sei sicuro di rimuovere quest'articolo?</p>
        <button @click="modalToggle">Annulla</button> <button @click="deleteArticle(articleId)">Rimuovi</button>
      </div>
    </div>
  </div>
</template>


<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';
import { ref } from 'vue';
import Editor from '@/components/Editor.vue';


export default {
  components: {
    VueDatePicker,
    Editor
  },
  data() {
    return {
      editorId: null,
      editors: [],
      indexImageEdition: "",
      toggleSelectYearDiv: true,
      year: ref(new Date().getFullYear()),
      addEditionDiv: false,
      selectYearDiv: false,
      showEditionsDiv: false,
      showArticlesDiv: false,
      editionDate: null,
      articles: null,
      editions: [],
      edition: null,
      articles: [],
      result: false,
      resultWithError: false,
      resultText: null,
      isModalVisibile: false,
      modalTitle: '',
      articleId: '',
      articleImage: '',
      articleInterview: false,
      articleTitle: '',
      articleSubtitle: '',
      articleBody: '',
      articlePosition: '1',
      articleFormVisible: true,
      articleRemoveDivVisible: false
    }
  },
  methods: {
    async submitIndexImageEdition() {
      const body = {
        date: this.edition.date,
        indexImage: this.indexImageEdition
      };
      const endpoint = apiConfig.endpoints.editionUpdate + "/" + this.edition.id_edition;
      const response = await request(endpoint, apiConfig.methods.put, body, store.getToken());
      if (response.ok) {
          const data = await response.json();
          if (data.message === "OK") {
              showNotification("Edizione aggiornata.", "success", 3000);
          } else {
              showNotification(data.message, "error", 3000);
          }
      } else {
          showNotification(apiConfig.errors.generic, "error", 3000);
      }
    },
    handleHtmlCodeUpdated(newHtmlCode) {
      // Gestisci il nuovo codice HTML aggiornato
      this.articleBody = newHtmlCode;
    },
    updateFormValue(newValue) {
      this.formValue = newValue;
    },
    addEditionDivToggle() {
      this.resultText = '';
      this.selectYearDiv = false;
      this.showEditionsDiv = false;
      this.showArticlesDiv = false;
      this.addEditionDiv = !this.addEditionDiv;
    },
    selectYearDivToggle() {
      this.resultText = '';
      this.addEditionDiv = false;
      this.showEditionsDiv = false;
      this.showArticlesDiv = false;
      this.selectYearDiv = true;
      this.toggleSelectYearDiv = true;
    },
    modalToggle() {
      this.isModalVisibile = !this.isModalVisibile;
      this.resetModalForm();
    },
    async handleEditionClick(edition) {
      this.articles = [];
      this.edition = edition;
      this.indexImageEdition = edition.index_image;
      this.showArticlesDiv = false;
      this.showEditionsDiv = false;
      const endpoint = apiConfig.endpoints.articleList + "/" + this.edition.id_edition;
      const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
      const data = await response.json();
      this.articles = data.result.articles;
      this.showArticlesDiv = true;
      this.toggleSelectYearDiv = false;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long' };
      const formattedDate = new Date(dateString).toLocaleDateString('it-IT', options);
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
    async searchEdition() {
      this.showArticlesDiv = false;
      this.resultText = '';
      const endpoint = apiConfig.endpoints.editionList + "/" + this.year;
      const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
      if (response.ok) {
        this.editions = [];
        this.result = true;
        const data = await response.json();
        if (data.message === "OK") {
          if (data.result.editions && Object.keys(data.result.editions).length > 0) {
            this.editions = data.result.editions;
            this.showEditionsDiv = true,
            this.resultWithError = false;
          } else {
            this.resultWithError = true;
            this.resultText = "Non ci sono edizioni nell'anno " + this.year;
          }
        } else {
          this.resultWithError = true;
          this.resultText = data.result.error;
        }
      } else {
        this.resultWithError = true;
        this.resultText = data.result.error;
      }
    },
    async submitEdition() {

      if(this.editorId === null) {
        showNotification("Attenzione. Il campo \"Editoriale di\" è vuoto", "warning", 3000);
        return;
      }

      const year = this.editionDate.year;
      const m = this.editionDate.month + 1;
      const month = m < 10 ? '0' + m : m;
      const day = '01';
      const recordEditionDate = year + "-" + month + "-" + day;
      var formData = new FormData();
      formData.append('userId', this.editorId);
      formData.append('date', recordEditionDate);
      var xhr = new XMLHttpRequest();
      xhr.open('POST', apiConfig.endpoints.editionCreate, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + store.getToken());
      xhr.send(formData);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          showNotification("Edizione salvata.", "success", 3000);
          location.reload();
        } else {
          showNotification("Errore durante la richiesta: "+xhr.statusText, "error", 3000);
        }
      };
      xhr.onerror = function () {
        showNotification("Errore durante la richiesta: "+xhr.statusText, "error", 3000);
      };
    },
    openModal(type, article = null) {
      this.isModalVisibile = true;
      this.modalType = type;
      this.articleFormVisible = true;
      this.articleRemoveDivVisible = false;
      if (this.modalType === 'add') {
        this.modalTitle = "Aggiungi articolo";
        this.articleId = null;
      } else if (this.modalType === 'edit') {
        this.editorId = article.id_user; //autore dell'articolo
        this.modalTitle = "Modifica articolo";
        this.articleImage = article.image;
        this.articleId = article.id_article;
        this.articlePosition = article.position;
        this.articleTitle = article.title;
        this.articleSubtitle = article.subtitle;
        this.articleBody = article.body;
        this.articleInterview = article.is_interview === 1 ? true : false;
      } else if (this.modalType === 'remove') {
        this.modalTitle = "Attenzione";
        this.articleId = article.id_article;
        this.articleFormVisible = false;
        this.articleRemoveDivVisible = true;
      }
    },
    async submitArticle() {

      if(!this.editorId || !this.articleTitle.trim() || !this.articleSubtitle.trim() || !this.articleBody.trim()) {
        showNotification("Attenzione. I campi 'Autore', 'Titolo', 'Sottotitolo' e 'Testo' sono obbligatori", "warning", 5000);
        return;
      }
      this.resultWithError = false;
      this.resultText = "";
      var formData = new FormData();
      formData.append('id_article', this.articleId);
      formData.append('id_edition', this.edition.id_edition);
      formData.append('id_user', this.editorId); //l'autore dell'articolo
      formData.append('is_interview', this.articleInterview);
      formData.append('title', this.articleTitle);
      formData.append('subtitle', this.articleSubtitle);
      formData.append('body', this.articleBody);
      formData.append('position', this.articlePosition);
      formData.append('image', this.articleImage);
      try {
        const endpoint = this.articleId ? apiConfig.endpoints.articleUpdate + "/" + this.articleId : apiConfig.endpoints.articleCreate;
        const method = apiConfig.methods.post;
        const response = await fetch(endpoint, {
          method: method,
          headers: {
            'Authorization': 'Bearer ' + store.getToken(),
            // Non impostare 'Content-Type' quando si utilizza FormData. Il browser lo imposterà per te
          },
          body: formData
        });
        const responseData = await response.json();
        if (response.ok && responseData.message === "OK") {
          showNotification("Articolo salvato correttamente.", "success", 3000);
        } else {
          showNotification(response.result.error, "error", 3000);
        }
      } catch (error) {
        showNotification("Errore. "+data.result.error, "error", 3000); 
      }
      this.resetModalForm();
      this.isModalVisibile = false;
      this.handleEditionClick(this.edition); //cambia articoli in tabella
  },
  resetModalForm() {
    this.editorId = null;
    this.articleId = '';
    this.articleTitle = '';
    this.articleSubtitle = '';
    this.articleBody = '';
    this.articleInterview = false;
    this.articlePosition = '1';
    this.articleImage = '';
  },
  async deleteArticle(id) {
    const response = await request(apiConfig.endpoints.articleDelete + "/" + id, apiConfig.methods.delete, null, store.getToken());
    if (response.ok) {
      const data = await response.json();
      if (data.message === "OK") {
        if (data.result.isDeleted) {
          showNotification("Articolo rimosso correttamente", "success", 3000); 
          this.handleEditionClick(this.edition); //cambia articoli in tabella
          this.isModalVisibile = false;
        } else {
          showNotification("Non è stato possibile rimuovere l'articolo.", "error", 3000); 
        }
      } else {
        showNotification(data.result.error, "error", 3000); 
      }
    } else {
      showNotification("Errore. "+data.result.error, "error", 3000); 
    }
  },
  insertImageHacking() {
    const button = document.getElementsByClassName('ck ck-button ck-off ck-file-dialog-button')[0];
    if (button) {
      button.style.backgroundColor = 'red';  // Esempio di azione personalizzata
    }
  },
  async fetchEditors() {
    const response = await request(apiConfig.noAuthEndpoints.editorList, apiConfig.methods.get, null, null);
    if (response.ok) {
      const data = await response.json();
      this.editors = data.result.editors;
    } else {
      showNotification(apiConfig.errors.generic, "error", 3000);
    }     
  },
},
mounted() {
  this.fetchEditors();
}
  
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 8px;
  width: auto;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.editions-table tr {
  cursor: pointer;
}

.admin-edition-section {
  width: 70%;
  padding: 0 30px 30px 30px;
}

.menu {
  text-align: center;
  margin-bottom: 25px;
}

.menu-button {
  margin-right: 10px;
}

.add-edition,
.select-year {
  margin: 25px 15% 0 15%;
  padding: 15px;
  background-color: #ebeced;
  border-radius: 10px;
}

label {
  font-weight: bold;
}

form {
  margin-top: 20px;
}

h2 {
  text-align: center;
}

.result {
  font-size: large;
  color: green;
}

.error {
  color: red !important;
}

.editions-div {
  width: 100%;
  padding: 0 5% 30px 5%;
  font-size: xx-large;
}

.modal {
  padding: 15px 15px 15px 15px;
  display: block;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  overflow-y: auto;
  /* Aggiunto per abilitare scrollbar verticale */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 15px;
  border: 1px solid #888;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  /* Aggiunto per abilitare scrollbar verticale */
}

.close {
  cursor: pointer;
  display: block;
  z-index: 200px;
  color: #515151;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: start;
    align-items: center;
}

.button-container .btn {
    margin-right: 15px; /* Aggiunge uno spazio a destra di ogni bottone */
}

.button-container .btn:last-child {
    margin-right: 0; /* Rimuove lo spazio a destra dall'ultimo bottone */
}
</style>