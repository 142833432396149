<template>
  <div v-if="doctor && doctor.tag">
    <Header />
    <main>
      <div id="breadcrumb">
        <div class="container">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/doctors">I nostri medici</a></li>
            <li>{{ doctor.title }} {{ doctor.full_name }}</li>
          </ul>
        </div>
      </div>
      <!-- /breadcrumb -->
      <div class="container margin_60">
        <div class="row">
          <aside class="col-xl-3 col-lg-4" id="sidebar">
            <div class="box_profile">
              <figure>
                <img :src="doctor.profile_image_link === '' ? 'https://www.placeholder.com/300x400' : doctor.profile_image_link" alt="" class="img-fluid" style="width: 306px">
              </figure>
              <small>{{ doctor.tag.tag }}</small>
              <h1>{{ doctor.title }} {{ doctor.user.name }}</h1>
              <span class="rating">
                <i class="icon_star voted"></i>
                <i class="icon_star voted"></i>
                <i class="icon_star voted"></i>
                <i class="icon_star voted"></i>
                <i class="icon_star voted"></i>
                <small>(145)</small>
                <a href="badges.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Badge Level"
                  class="badge_list_1"><img src="/img/badges/badge_1.svg" width="15" height="15" alt=""></a>
              </span>
              <ul class="statistic">
                <li>854 Visualizzazioni</li>
                <li>124 Pazienti</li>
              </ul>
              <ul class="contacts">
                <li>
                  <h6>Indirizzo</h6>{{ doctor.address }} {{ doctor.number }} - {{ doctor.cap }} {{ doctor.city }} ({{ doctor.code }})
                </li>
                <li>
                  <h6>Chiama</h6><a :href="`tel:${doctor.phone}`">{{ doctor.phone }}</a>
                </li>
              </ul>
              <div class="text-center"><a
                  :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.address + ' ' + doctor.number + ' - ' + doctor.cap + ' ' + doctor.city + ' (' + doctor.code + ')')}`"
                  target="_blank" class="btn_1 outline"><i class="icon_pin"></i> Vedi
                  mappa</a></div>
            </div>
          </aside>
          <!-- /asdide -->

          <div class="col-xl-9 col-lg-8">

            <div class="box_general_2 add_bottom_45">
              <div class="main_title_4">
                <h3><i class="icon_circle-slelected"></i>Seleziona data e ora</h3>
              </div>

              <div class="row add_bottom_45">
                <div class="col-lg-7">
                  <div class="form-group">
                    <!-- stampo il calendario -->
                    <div id="calendar"></div>
                    <input type="hidden" id="my_hidden_input">
                    <ul class="legend">
                      <li><strong></strong>Disponibile</li>
                      <li><strong></strong>Non disponibile</li>
                    </ul>
                  </div>
                </div>
                <!-- stampo gli orari disponibili -->
                <div class="col-lg-5">
                  <p v-if="availableTimes.length > 0"><b>Orari disponibili del dottore per il giorno {{ formatDate(selectedDate) }}</b></p>
                  <p v-else><b>Il dottore non ha orari per questa giornata</b></p>
                  <ul class="time_select version_2 add_top_20">
                    <li v-for="availableTime in availableTimes" :key="availableTime">
                      <input type="radio" :id="`radio${availableTime.replace(':', '.')}`" name="radio_time" :value="availableTime.replace(':', '.')" @change="selectedTime = availableTime">
                      <label :for="`radio${availableTime.replace(':', '.')}`">{{ availableTime }}</label>
                  </li>
                </ul>
                </div>
              </div>
              <!-- /row -->

              <div class="main_title_4">
                <h3><i class="icon_circle-slelected"></i>Seleziona trattamento</h3>
              </div>
              <ul class="treatments clearfix">
                <div v-for="s in services" :key="s.id">
                  <li>
                    <div class="checkbox">
                      <input type="checkbox" class="css-checkbox" :id="'service' + s.id" :name="'service' + s.id">
                      <label :for="'service' + s.id" class="css-label">{{ s.service_description }} <strong>€{{ s.price
                      }}</strong></label>
                    </div>
                  </li>
                </div>
              </ul>
              <hr>
              <div class="text-center"><button class="btn_1 medium" @click="prenota()">Prenota</button></div>
            </div>
            <!-- /box_general -->

            <div class="tabs_styled_2">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="general-tab" data-bs-toggle="tab" href="#general" role="tab"
                    aria-controls="general" aria-expanded="true">Info Generali</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="reviews-tab" data-bs-toggle="tab" href="#reviews" role="tab"
                    aria-controls="reviews">Recensioni</a>
                </li>
              </ul>
              <!--/nav-tabs -->

              <div class="tab-content">
                <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">

                  <p class="lead add_bottom_30">Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales
                    leo, eget blandit nunc tortor eu nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
                  <div class="indent_title_in">
                    <i class="pe-7s-user"></i>
                    <h3>Info generali</h3>
                    <p>Mussum ipsum cacilds, vidis litro abertis.</p>
                  </div>
                  <div class="wrapper_indent">
                    <p>Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc
                      tortor eu nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit.
                      Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Nullam
                      mollis. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapi.
                    </p>
                    <h6>Specializations</h6>
                    <div class="row">
                      <div class="col-lg-6">
                        <ul class="bullets">
                          <li>Abdominal Radiology</li>
                          <li>Addiction Psychiatry</li>
                          <li>Adolescent Medicine</li>
                          <li>Cardiothoracic Radiology </li>
                        </ul>
                      </div>
                      <div class="col-lg-6">
                        <ul class="bullets">
                          <li>Abdominal Radiology</li>
                          <li>Addiction Psychiatry</li>
                          <li>Adolescent Medicine</li>
                          <li>Cardiothoracic Radiology </li>
                        </ul>
                      </div>
                    </div>
                    <!-- /row-->
                  </div>
                  <!-- /wrapper indent -->

                  <hr>

                  <div class="indent_title_in">
                    <i class="pe-7s-news-paper"></i>
                    <h3>Istruzione</h3>
                    <p>Mussum ipsum cacilds, vidis litro abertis.</p>
                  </div>
                  <div class="wrapper_indent">
                    <p>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id,
                      mattis vel, nisi. Nullam mollis. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi
                      neque, aliquet vel, dapi.</p>
                    <h6>Curriculum</h6>
                    <ul class="list_edu">
                      <li><strong>New York Medical College</strong> - Doctor of Medicine</li>
                      <li><strong>Montefiore Medical Center</strong> - Residency in Internal Medicine</li>
                      <li><strong>New York Medical College</strong> - Master Internal Medicine</li>
                    </ul>
                  </div>
                  <!--  End wrapper indent -->


                </div>
                <!-- /tab_2 -->

                <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                  <div class="reviews-container">

                    <div class="review-box clearfix">
                      <figure class="rev-thumb"><img src="http://via.placeholder.com/150x150.jpg" alt="">
                      </figure>
                      <div class="rev-content">
                        <div class="rating">
                          <i class="icon_star voted"></i><i class="icon_star voted"></i><i class="icon_star voted"></i><i
                            class="icon_star voted"></i><i class="icon_star"></i>
                        </div>
                        <div class="rev-info">
                          Admin – April 03, 2016:
                        </div>
                        <div class="rev-text">
                          <p>
                            Sed eget turpis a pede tempor malesuada. Vivamus quis mi at leo pulvinar hendrerit. Cum sociis
                            natoque penatibus et magnis dis
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- End review-box -->

                    <div class="review-box clearfix">
                      <figure class="rev-thumb"><img src="http://via.placeholder.com/150x150.jpg" alt="">
                      </figure>
                      <div class="rev-content">
                        <div class="rating">
                          <i class="icon-star voted"></i><i class="icon_star voted"></i><i class="icon_star voted"></i><i
                            class="icon_star voted"></i><i class="icon_star"></i>
                        </div>
                        <div class="rev-info">
                          Ahsan – April 01, 2016
                        </div>
                        <div class="rev-text">
                          <p>
                            Sed eget turpis a pede tempor malesuada. Vivamus quis mi at leo pulvinar hendrerit. Cum sociis
                            natoque penatibus et magnis dis
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- End review-box -->

                    <div class="review-box clearfix">
                      <figure class="rev-thumb"><img src="http://via.placeholder.com/150x150.jpg" alt="">
                      </figure>
                      <div class="rev-content">
                        <div class="rating">
                          <i class="icon-star voted"></i><i class="icon_star voted"></i><i class="icon_star voted"></i><i
                            class="icon_star voted"></i><i class="icon_star"></i>
                        </div>
                        <div class="rev-info">
                          Sara – March 31, 2016
                        </div>
                        <div class="rev-text">
                          <p>
                            Sed eget turpis a pede tempor malesuada. Vivamus quis mi at leo pulvinar hendrerit. Cum sociis
                            natoque penatibus et magnis dis
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- End review-box -->
                  </div>
                  <!-- End review-container -->
                  <hr>
                  <div class="text-end"><a href="submit-review.html" class="btn_1 add_bottom_15">Submit review</a></div>
                </div>
                <!-- /tab_3 -->
              </div>
              <!-- /tab-content -->
            </div>
            <!-- /tabs_styled -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->

    </main>
    <!-- /main -->
    <Footer />
  </div>
</template>
  
<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from "@/store";
import CryptoJS from 'crypto-js';

export default {
  name: 'DoctorView',
  components: {
    Header, Footer
  },
  data() {
    return {
      user: null, //utente loggato
      doctor: null,
      services: null,
      month: 0,
      year: 0,
      selectedDate: null,
      selectedTime: null,
      availableTimes: [],
    }
  },
  watch: {
    '$route'(to, from) {
      this.id = to.params.id;
      this.getDoctor();
      this.getServices();
    }
  },
  created() {
    //Cancello tutte le date di tutti i dottori precendenti ad oggi
    this.agendaMassiveDelete();
    // Inizializzazione iniziale
    this.id = this.$route.params.id;
    this.getDoctor();
    this.getServices();
  },
  async mounted() {
    await this.getDoctor();
    this.initDatePicker();
    if(store.hasToken) {
      this.me();
    }
  },
  
  methods: {
    async me() {
      const response = await request(
        apiConfig.noAuthEndpoints.me,
        apiConfig.methods.post,
        null,
        store.getToken()
      );
      if (response.ok) {
        const data = await response.json();
        this.user = data;
        if(this.user.role  !== undefined) {
          this.isLogged = true
          this.isAdmin = this.user.role == "admin" || this.user.role == "editor";
        } else {
          this.isLogged = false
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
        responseKO();
      }
    },
    initDatePicker() {
        $('#calendar').datepicker({
            todayHighlight: true,
            daysOfWeekDisabled: [0],
            weekStart: 1,
            format: "dd-mm-yyyy",
            language: 'it',
        }).on('changeMonth', (e) => {
            this.handleCalendarChange();
        }).on('changeDate', (e) => {
            this.handleDateChange();
        });
        $('#calendar').datepicker('setDate', new Date());
        this.handleCalendarChange(); // Chiama una volta per inizializzare lo stato iniziale
    },

    handleCalendarChange() {
        // Riduci la visibilità del calendario qui se necessario
        this.getDateFromDatePickerSwitch();
        this.readMonth(); // Assicurati che questo metodo non causi aggiornamenti di stato che potrebbero innescare aggiornamenti del DOM
    },
    formatDate(dateString) {
      const [anno, mese, giorno] = dateString.split('-');
      const dataFormattata = `${giorno}/${mese.padStart(2, '0')}/${anno}`;
      return dataFormattata;
    },
    async handleDateChange() {
        const selectedDate = $('#calendar').datepicker('getDate');
        var data = new Date(selectedDate);
        var dataFormattata = [
            data.getFullYear(),
            this.padTo2Digits(data.getMonth() + 1),
            this.padTo2Digits(data.getDate())
        ].join('-');
        this.selectedDate = dataFormattata;
        await this.readDay();
        this.handleCalendarChange();
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    async agendaMassiveDelete() {
      const endpoint = apiConfig.noAuthEndpoints.agendaMassiveDelete;
      const response = await request(endpoint, apiConfig.methods.delete, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          //non faccio niente
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }

    },
    async getDoctor() {
      const endpoint = apiConfig.noAuthEndpoints.doctor + "/" + this.id;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.doctor = data.result.doctor;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }

    },
    async getServices() {
      const endpoint = apiConfig.noAuthEndpoints.doctorServices + "/" + this.id;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.services = data.result.services;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    async readMonth() {
      // Seleziona tutti i td con classe 'day' dentro il div con id 'calendar'
      const dayElements = document.querySelectorAll('.datepicker-days table td.day');
      // Cicla su tutti gli elementi trovati e aggiungi le classi
      dayElements.forEach((el) => {
        el.classList.add('disabled', 'disabled-date', 'day');
      });
      //adesso imposto le giornate disponibili
      const body = {
        user_id: this.doctor.user_id,
        month: this.month,
        year: this.year
      };
      const endpoint = apiConfig.noAuthEndpoints.agendaReadMonth;
      const response = await request(endpoint, apiConfig.methods.post, body, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          const days = data.result.days; // Assumendo che 'days' sia l'array di oggetti che hai descritto
          dayElements.forEach((el) => {
            if (!el.classList.contains("old") && !el.classList.contains("new")) {
              const elDay = parseInt(el.textContent, 10); // Converti in numero rimuovendo eventuali zeri iniziali
              days.forEach((dayObj) => {
                const dayDate = new Date(dayObj.date);
                const day = dayDate.getDate(); // Ottieni il giorno del mese come numero
                if (elDay === day) {
                  // Se il giorno corrisponde, modifica la classe di 'el'
                  el.classList.add('day');
                  // Potresti voler rimuovere 'disabled' e 'disabled-date' se necessario
                  el.classList.remove('disabled', 'disabled-date');
                }
              });
            }
          });
          
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    async createAppointment() {
      const body = {
        user_id: this.user.id,
        doctor_id: this.doctor.id,
        date: this.selectedDate + " " + this.selectedTime + ":00"
      }
      const endpoint = apiConfig.endpoints.appointmentCreate;
      const response = await request(endpoint, apiConfig.methods.post, body, store.getToken());
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          const appointment = data.result;
          //rimuovo la disponibilità del dottore
          const body2 = {
            user_id: this.doctor.user_id,
            date: this.selectedDate,
            times: [ this.selectedTime ]
          };
          const endpoint2 = apiConfig.endpoints.agendaDelete;
          const response2 = await request(endpoint2, apiConfig.methods.delete, body2, store.getToken());
          if (response2.ok) {
            const data2 = await response2.json();
            if (data2.message === "OK") {
              //non faccio niente
            } else {
              showNotification(data2.result.error, "error", 3000);
            }
          } else {
            showNotification(apiConfig.errors.generic, "error", 3000)
          }
          showNotification("Appuntamento creato con successo.", "success", 3000);
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000);
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    async readDay() {
      const body = {
        user_id: this.doctor.user_id,
        date: this.selectedDate
      };
      const endpoint = apiConfig.noAuthEndpoints.agendaReadDay
      const response = await request(endpoint, apiConfig.methods.post, body, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.availableTimes = data.result.times.map(timeObj => timeObj.time);
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    getDateFromDatePickerSwitch() {
      const datePickerSwitchElement = document.querySelector('.datepicker-switch');
      if (datePickerSwitchElement) {
        const datePickerSwitchText = datePickerSwitchElement.textContent;

        const monthMap = {
          "Gennaio": 1, "Febbraio": 2, "Marzo": 3, "Aprile": 4, "Maggio": 5, "Giugno": 6,
          "Luglio": 7, "Agosto": 8, "Settembre": 9, "Ottobre": 10, "Novembre": 11, "Dicembre": 12
        };

        const [monthText, year] = datePickerSwitchText.split(' ');

        const month = monthMap[monthText];

        // Aggiornare i dati del componente Vue
        this.month = month;
        this.year = parseInt(year); // Assicurati che l'anno sia un numero
      } else {
        //console.error('Elemento .datepicker-switch non trovato.');
      }
    },
    handleCalendar() {
      this.getDateFromDatePickerSwitch();
      this.readMonth();
    },
    gotoToAppointmentCreated() {
      const dataDaPassare = 'Gentile '+this.user.name+", l'appuntamento con il dottor "+this.doctor.user.name + " per la data "+ this.formatDate(this.selectedDate) +" " +this.selectedTime+" è stato creato.";
      const encryptedData = CryptoJS.AES.encrypt(dataDaPassare, 'chiaveSegreta').toString();
      this.$router.push({ name: 'AppointmentCreated', query: { data: encryptedData } });
    },
    async prenota() {
      if(store.hasToken) {
        //VERIFICO CHE IL DOTTORE NON SI PRENOTI PER SE STESSO
        if(this.doctor.user_id === this.user.id) {
          showNotification("Attenzione. Non puoi prenotare un servizio al dottore che coincide con l'utente loggato.", "warning", 4000);
          return;
        }
        //PROCEDO CON L'APPUNTAMENTO
        if (!this.selectedDate || !this.selectedTime) {
          showNotification("Errore. Devi selezionare sia una data che un orario.", "error", 3000);
        } else {
          //faccio la prenotazione dell'appuntamento
          await this.createAppointment();
          this.gotoToAppointmentCreated()
        }

      } else {
        window.location.href = "/";
      }
      
    }
  },

}
</script>
  
<style scoped>
.statistic li {
  margin-right: 5px;
}
</style>