<template>
    <div>
        <Header />
        <main>
            <div class="container">
                <div class="resetForm">
                    <h2>Reset Password</h2>
                    <div class="row">
                        <form @submit.prevent="send">
                            <div class="box_login last">
                                <div class="form-group">
                                    <input type="password" id="password1" class="form-control" ref="p1" placeholder="Password"
                                        v-model="password1" />
                                </div>
                                <div class="form-group">
                                    <input type="password" id="password2" class="form-control" placeholder="Ripeti Password"
                                        v-model="password2" />
                                </div>
                                <div class="form-group">
                                    <button class="btn_1">Invia</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {

    name: "RecoveryView",
    components: {
        Header, Footer
    },
    data() {
        return {
            token: "",
            password1: "",
            password2: ""
        };
    },
    mounted() {
        this.$refs.p1.focus();
        this.token = this.$route.query.token;
    },
    methods: {
        async send() {
            const body = {
                password1: this.password1,
                password2: this.password2,
                token: this.token
            };
            const endpoint = apiConfig.noAuthEndpoints.recoveryChangePassword;
            const response = await request(endpoint, apiConfig.methods.post, body, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    showNotification("Cambio password effettuato.", "success", 3000);
                    window.location.href = "/";
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000)
            }
        }
    }
}
</script>

<style scoped>

.resetForm {
    padding: 25px;
    text-align: center;
}

.box_login {
    margin: auto !important;
    float: none !important;
}
</style>