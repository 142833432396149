<template>
    <div>
    <Header />
    <main>
        <div class="bg_color_2">
            <div class="container margin_60_35">
                <div id="login-2" class="myLoginClass">
                    <div class="col-md-12 logone-login">
                        <img src="/img/ie-fav.png" />
                    </div>
                    <div class="box_form clearfix">
                        <div class="esito">
                            <p v-if="data">{{ data }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer />
    </div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CryptoJS from 'crypto-js';
export default {
    name: 'AppointmentCreatedView',
    components: {
        Header, Footer
    },
    data() {
        return{
            data: null
        }
    },
    mounted() {
        const encryptedData = this.$route.query.data;
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, 'chiaveSegreta').toString(CryptoJS.enc.Utf8);
        this.data = decryptedData;
    }

}
</script>

<style scoped>
.esito {
    margin: 15px;
    font-size: 24px;
}
</style>