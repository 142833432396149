<template>
    <div class="article">
        <Header />
        <main>
            <div id="breadcrumb" class="breadcrumb">
                <div class="container">
                    <ul>
                        <li><a href="#">InfoesteticaMag</a></li>
                        <li><a href="#">Edizioni</a></li>
                        <li><a href="#">{{ this.year }}</a></li>
                        <li><a :href="'/edition/'+this.month+'/'+this.year">{{ months[this.month] }}</a></li>
                    </ul>
                </div>
            </div>
            <!-- /breadcrumb -->
            
            <div class="container">
                <div class="row" v-if="article">
                    <div class="article">
                        <h1>{{ article.title }}</h1>
                        <h2 v-if="article.subtitle">{{ article.subtitle }}</h2>
                        <div class="article-image">
                            <img :src="articleImageSrc" alt="Immagine descrittiva" style="width:200px; height: 200px">
                        </div>
                        <div v-html="article.body"></div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>



<script>
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header, Footer
    },
    data() {
        return {
            id: 0,
            year: null,
            month: null,
            article: null,
            articleImageSrc: null,
            months: [
                'Non specificato',
                'Gennaio',
                'Febbraio',
                'Marzo',
                'Aprile',
                'Maggio',
                'Giugno',
                'Luglio',
                'Agosto',
                'Settembre',
                'Ottobre',
                'Novembre',
                'Dicembre'
            ]
        }
    },
    watch: {
        '$route'(to, from) {
            // Aggiorna l'id quando la route cambia
            this.id = to.params.id;
            this.year = to.params.year;
            this.month = to.params.month;
            this.fetchArticle();
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        this.year = this.$route.params.year;
        this.month = this.$route.params.month;
        this.fetchArticle();
    },
    methods: {
        async fetchArticle() {
            const endpoint = apiConfig.noAuthEndpoints.editionArticle + "/" + this.id;
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.article = data.result.article;
                    this.articleImageSrc = this.article.image !== null ? this.article.image : 'https://via.placeholder.com/200x200.jpg'               
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        }
    }
}

</script>

<style scoped>
.article {
    text-align: center;
    padding-bottom: 30px;
}
.article-image {
    margin-top: 25px;
    margin-bottom: 25px;
}
.breadcrumb {
    text-align: left;
}
</style>