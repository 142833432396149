<template>
    <div>
        <main>
            <form @submit.prevent="submitForm">
                
                <div class="form-group" v-if="doctorChanged && tags">
                    <small>Selezione il tuo indirizzo medico</small>
                    <select class="form-select" v-model="selectedTagId">
                        <option v-for="(tag) in tags" :value="tag.id">{{ tag.tag.toUpperCase() }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-sm">Salva</button>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';

export default {

    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        doctor: {
            type: Object,
            default: () => ({})
        }
    },

    mounted() {
        this.getTags();
        this.doctorChanged = this.doctor
        if (this.doctorChanged && this.doctorChanged.doctors_tag_id) {
            this.selectedTagId = this.doctorChanged.doctors_tag_id;
        }
    },

    data() {
        return {
            tags: [],
            doctorChanged: null,
            selectedTagId: null
        }
    },

    methods: {
        async getTags() {
            const endpoint = apiConfig.endpoints.doctorTagsList;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.tags = data.result.tags;
                } else {
                    showNotification(data.message, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async submitForm() {
            const body = {
                doctors_tag_id: this.selectedTagId
            };
            const endpoint = apiConfig.endpoints.doctorUpdateDoctorTag + "/" + this.doctorChanged.id;
            const response = await request(endpoint, apiConfig.methods.put, body, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.doctorChanged = data.doctor;
                    this.selectedTagId = this.doctorChanged.doctors_tag_id;
                    showNotification("Utente modificato con successo.", "success", 3000);
                } else {
                    showNotification(data.message, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        }
    },  

}
</script>

<style scoped>
    form {
        padding: 15px 0 15px 0;
    }   
</style>