<template>
  <div>
    <Header />
    <main>
      <div id="results">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h4><strong>Risultati {{ size }}</strong> di {{ totalCount }}</h4>
            </div>
            <div class="col-md-6">
              <div class="search_bar_list">
                <form @submit.prevent="search">
                  <input type="text" class="form-control" v-model="searchText" placeholder="Cerca per nome, trattamento, città o lascia vuoto per cercare tutti...">
                  <input type="submit" value="Cerca">
                </form>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
        <!-- /container -->
      </div>
      <!-- /results -->

      <div class="filters_listing">
        <div class="container">
          <ul class="clearfix">
            <li>
              <h6>Filtro</h6>
              <div class="switch-field">
                <input type="radio" id="all" name="type_patient" value="all" @click="filterTagDoctors('all')" checked>
                <label for="all">Tutti</label>
                <input type="radio" id="doctors" name="type_patient" value="doctors">
                <label for="doctors" @click="filterTagDoctors('chirurghi plastici')">Chirurghi Plastici</label>
                <input type="radio" id="clinics" name="type_patient" value="clinics"
                  @click="filterTagDoctors('medici estetici')">
                <label for="clinics">Medici Estetici</label>
              </div>
            </li>

            <li>
              <h6>Vedi altri filtri</h6>
              <select name="orderby" class="selectbox">
                <option value="Closest">Vicinanza</option>
                <option value="Best rated">Popolarità</option>
                <option value="Men">Uomo</option>
                <option value="Women">Donna</option>
              </select>
            </li>
          </ul>
        </div>
        <!-- /container -->
      </div>
      <!-- /filters -->

      <div class="container margin_60_35">
        <div class="row">

          <h2 style="text-align: center" v-if="filteredDoctors.length == 0">Nessun dottore trovato.</h2>

          <div class="col-lg-12" v-if="filteredDoctors.length > 0">

            <h2 style="text-align: center">Risultati della ricerca</h2>

            <nav aria-label="Navigazione paginazione" class="add_top_20">
              <ul class="pagination pagination-sm">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Pagina Precedente</a>
                </li>
                <li class="page-item" v-for="page in pages" :key="page" :class="{ active: currentPage === page }">
                  <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === pages }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Pagina Successiva</a>
                </li>
              </ul>
            </nav>

            <!-- /Schedina dottore -->
            <div v-for="d in filteredDoctors" :key="d.id">
              <div class="strip_list">

                <div class="row">

                  <div class="col-lg-7">
                    <figure>
                      <a href=""><img
                          :src="d.thumbnail_image_link === '' ? 'https://via.placeholder.com/80x80' : d.thumbnail_image_link"
                          alt="" class="doctor-image"></a>
                    </figure>
                    <small>{{ d.tag.tag }}</small>
                    <h3>
                      {{ d.title }} {{ d.full_name }}
                    </h3>
                    <p>{{ d.address }} {{ d.number }} - {{ d.cap }} {{ d.city }} ({{ d.code }})</p>
                    <span class="rating">
                      <i class="icon_star voted"></i>
                      <i class="icon_star voted"></i>
                      <i class="icon_star voted"></i>
                      <i class="icon_star voted"></i>
                      <i class="icon_star voted"></i>
                      <small>(145)</small>
                    </span>
                    <a href="badges.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Badge Level"
                      class="badge_list_1">
                      <img src="/img/badges/badge_1.svg" width="15" height="15" alt="">
                    </a>
                    <ul>
                      <li><a :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(d.address + ' ' + d.number + ' - ' + d.cap + ' ' + d.city + ' (' + d.code + ')')}`" 
                          target="_blank" class="btn_listing">Vedi sulla mappa</a></li>
                      <li><a :href="`tel:${d.phone}`">Chiama</a></li>
                      <li><a :href="`/doctor/${d.id}`">Visualizza profilo</a></li>
                    </ul>
                  </div>
                  <div class="col-lg-5">
                    <form>
                      <div class="title">
                        <h3>Prenota la tua visita</h3>
                        <small>Lunedì - Venerdì | 09.00 - 18.00</small>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="meeting-time">Scegli la data l'ora:</label>
                            <input type="datetime-local" id="meeting-time" name="meeting-time" value="2024-01-12T19:30"
                              min="2018-06-07T00:00" max="2018-06-14T00:00" />
                          </div>
                        </div>
                      </div>
                      <a :href="`/doctor/${d.id}`" class="btn_1 full-width">Prenota la tua visita</a>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <nav aria-label="Navigazione paginazione" class="add_top_20">
              <ul class="pagination pagination-sm">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Pagina Precedente</a>
                </li>
                <li class="page-item" v-for="page in pages" :key="page" :class="{ active: currentPage === page }">
                  <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === pages }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Pagina Successiva</a>
                </li>
              </ul>
            </nav>



          </div>

        </div>

      </div>

    </main>
    <Footer />
  </div>
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request } from '@/config/api';

export default {
  name: 'DoctorsView',
  components: {
    Header, Footer
  },
  data() {
    return {
      doctors: [],
      filteredDoctors: [],
      size: 0,
      totalCount: 0,
      pages: 0,
      offset: 0,
      currentPage: 1,
      searchText: "",
    }
  },
  mounted() {
    this.searchText = this.$route.query.searchText;
    this.getAllDoctors();
  },
  methods: {
    async getAllDoctors() {
      var text = "__all__";
      if(this.searchText !== "") text = this.searchText;
      const endpoint = apiConfig.noAuthEndpoints.doctorList + "/" + this.offset + "/" + text;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.totalCount = data.result.totalCount;
          this.size = text !== "__all__" ? data.result.size : this.totalCount;
          this.pages = Math.ceil(this.size / 5);
          this.doctors = data.result.doctors;
          this.filteredDoctors = this.doctors;
        } else {
          showNotification(data.result.error, "error", 3000);
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
      }
    },
    filterTagDoctors(value) {
      if (value === "all") {
        this.filteredDoctors = this.doctors;
      } else {
        this.filteredDoctors = this.doctors.filter(doctor => doctor.tag.tag === value);
      }
    },
    changePage(page) {
      if (page < 1 || page > this.pages) return;
      const pageSize = 5;
      this.offset = (page - 1) * pageSize;
      this.currentPage = page;
      this.getAllDoctors();
    },
    search() {
      this.doctors = [],
      this.filteredDoctors = [],
      this.size = 0,
      this.totalCount = 0,
      this.pages = 0,
      this.offset = 0,
      this.currentPage = 1,
      this.getAllDoctors();
    }

  },

}
</script>

<style scoped>
.doctor-image {
  width: 80px;
  height: 80px;
}
</style>