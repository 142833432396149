<template>
    <div>
        <Header />
        <main>
            <div class="bg_color_2">
                <div class="container margin_60_35">
                    <div id="login-2" class="myLoginClass">
                        <div class="col-md-12 logone-login">
                            <img src="/img/ie-fav.png" />
                        </div>
                        <h1>Registrazione a Infoestetica</h1>
                        <div class="box_form clearfix">
                            <form @submit.prevent="signup">
                                <div class="signup-box">
                                    <div class="form-group signup-box-form-group">
                                        <small>Nome e cognome</small>
                                        <input type="text" class="form-control" ref="nameInput" v-model="name" />
                                    </div>
                                    <div class="form-group signup-box-form-group">
                                        <small>Email</small>
                                        <input type="email" class="form-control" v-model="email" />
                                    </div>
                                    <div class="form-group signup-box-form-group">
                                        <small>Password</small>
                                        <input type="password" class="form-control" v-model="password1" />
                                    </div>
                                    <div class="form-group signup-box-form-group">
                                        <small>Conferma password</small>
                                        <input type="password" class="form-control" v-model="password2" />
                                    </div>
                                    <div class="form-group signup-box-form-group">
                                        <small>Tipo di utente</small>
                                        <select class="form-select" v-model="role" @change="handleRoles">
                                            <option value="user" selected>Utente</option>
                                            <option value="doctor">Dottore</option>
                                        </select>
                                    </div>
                                    <div v-if="isDoctorSignup">                                        
                                        <div class="form-group signup-box-form-group">
                                            <small>Titolo</small>
                                            <select class="form-select" v-model="title">
                                                <option value="title-doctor" selected>Dott.</option>
                                                <option value="title-prof">Prof.</option>
                                            </select>
                                        </div>
                                        <div class="form-group signup-box-form-group">
                                            <small>Numero di telefono</small>
                                            <vue-tel-input class="form-control" :inputOptions="phoneOptions" v-model="phone"></vue-tel-input>
                                        </div>
                                        <div class="form-group signup-box-form-group">
                                            <small>Indirizzo</small>
                                            <input type="text" class="form-control" v-model="address" />
                                        </div>
                                        <div class="form-group signup-box-form-group">
                                            <small>Numero civico</small>
                                            <input type="text" class="form-control" v-model="number" />
                                        </div>
                                        <div class="form-group signup-box-form-group">
                                            <small>CAP</small>
                                            <input type="text" class="form-control" v-model="cap" maxlength="10" />
                                        </div>
                                        <div class="form-group signup-box-form-group">
                                            <small>Città o paese</small>
                                            <input type="text" class="form-control" v-model="city" />
                                        </div>
                                        <div class="form-group signup-box-form-group">
                                            <small>Seleziona una provincia</small>
                                            <select v-model="code" class="form-select">
                                                <option v-for="(name, code) in provinces" :key="code" :value="code">{{ name }} ({{ code }})</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group signup-box-form-group">
                                        <button class="btn_1">Registrati</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request } from '@/config/api';
export default {

    name: 'SignupView', 

    /*props() {
        inputOptions.placeholder = "telefono"
    },*/
  
    components: {
        Header, Footer
    },
    mounted() {
        this.$refs.nameInput.focus();
    },
    data() {
        return {
            name: "",
            email: "",
            password1: "",
            password2: "",
            role: "user",
            title: "title-doctor",
            phone: "",
            address: "",
            number: "",
            cap: "",
            city: "",
            code: "",
            isDoctorSignup: false,
            phoneOptions: { placeholder: "" },
            provinces: {
                AG: 'Agrigento',
                AL: 'Alessandria',
                AN: 'Ancona',
                AO: 'Aosta',
                AR: 'Arezzo',
                AP: 'Ascoli Piceno',
                AT: 'Asti',
                AV: 'Avellino',
                BA: 'Bari',
                BT: 'Barletta-Andria-Trani',
                BL: 'Belluno',
                BN: 'Benevento',
                BG: 'Bergamo',
                BI: 'Biella',
                BO: 'Bologna',
                BZ: 'Bolzano',
                BS: 'Brescia',
                BR: 'Brindisi',
                CA: 'Cagliari',
                CL: 'Caltanissetta',
                CB: 'Campobasso',
                CI: 'Carbonia-Iglesias',
                CE: 'Caserta',
                CT: 'Catania',
                CZ: 'Catanzaro',
                CH: 'Chieti',
                CO: 'Como',
                CS: 'Cosenza',
                CR: 'Cremona',
                KR: 'Crotone',
                CN: 'Cuneo',
                EN: 'Enna',
                FM: 'Fermo',
                FE: 'Ferrara',
                FI: 'Firenze',
                FG: 'Foggia',
                FC: 'Forlì-Cesena',
                FR: 'Frosinone',
                GE: 'Genova',
                GO: 'Gorizia',
                GR: 'Grosseto',
                IM: 'Imperia',
                IS: 'Isernia',
                SP: 'La Spezia',
                AQ: 'L\'Aquila',
                LT: 'Latina',
                LE: 'Lecce',
                LC: 'Lecco',
                LI: 'Livorno',
                LO: 'Lodi',
                LU: 'Lucca',
                MC: 'Macerata',
                MN: 'Mantova',
                MS: 'Massa-Carrara',
                MT: 'Matera',
                VS: 'Medio Campidano',
                ME: 'Messina',
                MI: 'Milano',
                MO: 'Modena',
                MB: 'Monza e Brianza',
                NA: 'Napoli',
                NO: 'Novara',
                NU: 'Nuoro',
                OG: 'Ogliastra',
                OT: 'Olbia-Tempio',
                OR: 'Oristano',
                PD: 'Padova',
                PA: 'Palermo',
                PR: 'Parma',
                PV: 'Pavia',
                PG: 'Perugia',
                PU: 'Pesaro e Urbino',
                PE: 'Pescara',
                PC: 'Piacenza',
                PI: 'Pisa',
                PT: 'Pistoia',
                PN: 'Pordenone',
                PZ: 'Potenza',
                PO: 'Prato',
                RG: 'Ragusa',
                RA: 'Ravenna',
                RC: 'Reggio Calabria',
                RE: 'Reggio Emilia',
                RI: 'Rieti',
                RN: 'Rimini',
                RM: 'Roma',
                RO: 'Rovigo',
                SA: 'Salerno',
                SS: 'Sassari',
                SV: 'Savona',
                SI: 'Siena',
                SR: 'Siracusa',
                SO: 'Sondrio',
                TA: 'Taranto',
                TE: 'Teramo',
                TR: 'Terni',
                TO: 'Torino',
                TP: 'Trapani',
                TN: 'Trento',
                TV: 'Treviso',
                TS: 'Trieste',
                UD: 'Udine',
                VA: 'Varese',
                VE: 'Venezia',
                VB: 'Verbano-Cusio-Ossola',
                VC: 'Vercelli',
                VR: 'Verona',
                VV: 'Vibo Valentia',
                VI: 'Vicenza',
                VT: 'Viterbo',
            },
        };
    },
    methods: {
        async signup() {
            try {
                // prima verifica il reCAPTCHA
                const token = await this.$recaptcha('signup');
                if (token) { 
                    // assicurati che il token sia stato ottenuto
                    // Controllo base per tutti gli utenti
                    if(this.email && this.password1 && this.password2) {
                        if(this.password1 === this.password2) {
                            // Controllo aggiuntivo per il ruolo "doctor"
                            if(this.role === "doctor" && 
                            this.title && this.phone && this.address && 
                            this.number && this.cap && this.city && this.code) {
                                // Creazione dell'oggetto body per "doctor"
                                const body = {
                                    name: this.name,
                                    email: this.email,
                                    password: this.password1,
                                    role: this.role,
                                    title: this.title,
                                    phone: this.phone,
                                    address: this.address,
                                    number: this.number,
                                    cap: this.cap,
                                    city: this.city,
                                    code: this.code,
                                    recaptchaToken: token
                                };
                                const endpoint = apiConfig.noAuthEndpoints.doctorCreate;
                                const response = await request(endpoint, apiConfig.methods.post, body, null);
                                if (response.ok) {
                                    const data = await response.json();
                                    if(data.message == "OK") {
                                        showNotification("Utente creato con successo.", "success", 3000);
                                        window.location.href = "/";
                                    } else {
                                        showNotification("Si è verificato un errore durante la creazione dell'utente.", "error", 3000);
                                    }
                                } else {
                                    showNotification(apiConfig.errors.generic, "error", 3000)
                                }

                            } else if(this.role === "user") {
                                // Creazione dell'oggetto body per "user"
                                const body = {
                                    name: this.name,
                                    email: this.email,
                                    password: this.password1,
                                    role: this.role,
                                    recaptchaToken: token
                                };
                                const endpoint = apiConfig.noAuthEndpoints.userCreate;
                                const response = await request(endpoint, apiConfig.methods.post, body, null);
                                if (response.ok) {
                                    const data = await response.json();
                                    if(data.message == "OK") {
                                        showNotification("Utente creato con successo.", "success", 3000);
                                        window.location.href = "/";
                                    } else {
                                        showNotification("Si è verificato un errore durante la creazione dell'utente.", "error", 4000);
                                    }
                                } else {
                                    showNotification(apiConfig.errors.generic, "error", 3000)
                                }
                            } else {
                                // Messaggio di errore per campi mancanti del "doctor"
                                showNotification("Attenzione. Tutti i campi sono obbligatori.", "warning", 3000);
                            }
                        } else {
                            showNotification("Attenzione. Le password non coincidono.", "warning", 3000);
                        }
                    } else {
                        showNotification("Attenzione. Tutti i campi sono obbligatori.", "warning", 3000);
                    }
                } else {
                    showNotification("Attenzione. Si prega di completare la verifica reCAPTCHA.", "warning", 3000);
                }
            } catch (error) {
                showNotification("Errore durante la registrazione. Per favore, riprova.", "error", 3000);
            }
        },

        handleRoles() {
            this.isDoctorSignup = this.role === "doctor";
        },

        
        
    },


}

</script>

<style scoped>

.signup-box {
    padding: 15px;
}

.signup-box-form-group {
    margin-bottom: 15px;
}

</style>

